import React, { Component } from "react";

class MenuItem extends Component {
  render() {
    return (
      <div className="category">
        <li>
          <a href="#" onClick={() => this.props.click(this.props.name)}>
            {this.props.name}
          </a>
        </li>
      </div>
    );
  }
}
export default MenuItem;
