import React, { Component } from "react";
import logo from "../img/logo1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import { Icon, Button, Avatar } from "@material-ui/core";
import usuarios from "../hooks/usuarios";
import "../styles/acsstyle.css";
import "boxicons";

class Navegacion extends Component {
  render() {
    const [imagen, user] = usuarios();
    return (
      <Navbar className="navbar navbar-dark bg-dark">
        {/* BARRA MENU */}
        <div className="nav-item dropdown ">
          <li
            className="nav-link"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <box-icon name="menu" color="red"></box-icon>
            {/* <Icon color="secondary">menu</Icon> */}
          </li>
          <ul
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li>
              <a href="/main">
                <i style={{ height: 25, marginRight: 10 }}>
                  <box-icon name="home" color="black"></box-icon>
                </i>
                <span className="links_name">Inicio</span>
              </a>
            </li>
            <li>
              <a href="/historial">
                <i style={{ height: 25, marginRight: 10 }}>
                  <box-icon name="history" color="black"></box-icon>
                </i>
                <span className="links_name">Historial</span>
              </a>
            </li>
            <li>
              <a href="/methodpage">
                <i style={{ height: 25, marginRight: 10 }}>
                  <box-icon
                    name="edit-alt"
                    type="solid"
                    color="black"
                  ></box-icon>
                </i>
                <span className="links_name">Edicion</span>
              </a>
            </li>

            {/* <li> <a className="dropdown-item disable" href="#" > </a></li> */}
          </ul>
        </div>

        {/* LOGO Y NOMBRE */}

        <div className="container-fluid">
          <a className="txtNavBar navbar-brand" href="/main">
            <img
              src={logo}
              alt=""
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            Productos y Servicios Dehesas
          </a>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            {/* AVATAR */}

            {/* <div className="dropdown ml-auto dropstart ">
              <Button
                className="nav-link"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Avatar>{imagen}</Avatar>
              </Button>
              <ul
                className="dropdown-menu "
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li align="center">{user}</li>
                <li>
                  <a className="dropdown-item disable" href="/">
                    <button className="dropdown-item disable">Logout</button>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </Navbar>
    );
  }
}

export default Navegacion;
