import React, { useState } from "react";
import Navegacion from "../componentes/Navbar";
import "../styles/acsstyle.css";
// import Dropdown from "../componentes/dropdown";
import Tablegastos from "../containers/container/tablegastos";
import Modal from "../componentes/ventanamodal";
import { makeStyles, Button, Icon } from "@material-ui/core";
import useModal from "../hooks/useModal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyle = makeStyles({
  header: {
    display: "flex",
  },
  title: {
    fontSize: "50px",
    letterSpacing: "10px",
    color: "white",
    fontFamily: "",
    fontVariantCaps: "all-small-caps",
    marginLeft: "25px",
  },
  bar: {
    position: "absolute",
    marginLeft: "80%",
    display: "flex",
    marginTop: "25px",
  },
  input: {
    marginLeft: 3,
    minHeight: 40,
    marginTop: 10,
    marginBottom: 8,
  },
  btn: {
    backgroundColor: "#00416A",
    color: "white",
    width: 180,
    borderRadius: 10,
  },
  btnsend: {
    position: "end",
    marginLeft: "82%",
  },
});

// const gasto = [
//   { value: "Gasto en general" },
//   { value: "Gasto" },
//   { value: "Gasto prueba" },
//   { value: "Otro" },
// ];

// const tienda = [
//   { value: "HEB" },
//   { value: "Mode" },
//   { value: "Roma" },
//   { value: "GranD" },
// ];

const tableCells = [
  { cell: "Gasto" },
  { cell: "Tienda" },
  { cell: "Factura" },
  { cell: "Descripcion" },
  { cell: "Fecha" },
];

// const handleSubmit=()=>{

// }

function Gasolina(props) {
  const classes = useStyle();
  const [isOpenNewVuelta, openNewVuelta, closeNewVuelta] = useModal();
  const [validarOpciones, setValidarOpciones] = useState();

  const abrirNewVuelta = () => {
    openNewVuelta();
    setValidarOpciones(false);
    console.log(validarOpciones);
  };

  // const [page, setPage] = useState(0);
  // const [rowsPerPage] = useState(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const count = vueltas.length;

  return (
    <div className="bgpage">
      <Navegacion />
      <div>
        <header className={classes.header}>
          <h3 className={classes.title}>Gastos</h3>
          <div className={classes.bar}>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              className={classes.btn}
              onClick={abrirNewVuelta}
            >
              Nueva Gasto
            </Button>
            <Modal
              show={isOpenNewVuelta}
              handleClose={closeNewVuelta}
              title="Agregar nueva gasto"
              clase="modal-main"
            >
              {/* <Dropdown
                txtvalor="Gasto"
                valor={gasto}
                validarOpciones={validarOpciones}
              /> */}
              <input
                placeholder="Tienda"
                className={classes.input}
                style={{ width: "99%" }}
              ></input>
              <input
                placeholder="Factura"
                className={classes.input}
                style={{ width: "99%" }}
              ></input>
              <input
                placeholder="Descripcion"
                className={classes.input}
                style={{ width: "99%" }}
              ></input>
              <input
                placeholder="Fecha"
                className={classes.input}
                style={{ width: "99%" }}
              ></input>

              <Button
                align="rigth"
                className={classes.btnsend}
                color="default"
                startIcon={<Icon>send</Icon>}
              >
                Enviar
              </Button>
            </Modal>
          </div>
        </header>

        <Tablegastos tableCells={tableCells} />
      </div>
    </div>
  );
}

export default Gasolina;
