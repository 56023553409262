import React, { useState } from "react";
import Navegacion from "../componentes/Navbar";
import "../styles/acsstyle.css";
import Dropdown from "../componentes/dropdown";
import Tablehook from "../containers/container/tablehook";
import Modal from "../componentes/ventanamodal";
import { makeStyles, Button, Icon, TablePagination } from "@material-ui/core";
import useModal from "../hooks/useModal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useTiendas, useVueltas } from "../hooks/useDatabase";
import { Database } from "../api/Database";

const useStyle = makeStyles({
  header: {
    display: "flex",
  },
  title: {
    fontSize: "50px",
    letterSpacing: "10px",
    color: "white",
    fontFamily: "",
    fontVariantCaps: "all-small-caps",
    marginLeft: "25px",
  },
  bar: {
    position: "absolute",
    marginLeft: "80%",
    display: "flex",
    marginTop: "25px",
  },
  input: {
    marginLeft: 14,
    minWidth: 380,
    minHeight: 40,
    marginTop: 10,
    marginBottom: 2,
  },
  input2: {
    marginLeft: 14,
    minWidth: 183,
    minHeight: 40,
    marginBottom: 0,
    marginTop: 10,
  },
  btn: {
    backgroundColor: "#00416A",
    color: "white",
    width: 180,
    borderRadius: 10,
  },
  btnsend: {
    position: "end",
    marginLeft: "82%",
    marginBottom: "5px",
  },
  label: {
    fontSize: "15px",
    marginLeft: "68px",
  },
  label1: {
    fontSize: "15px",
    marginLeft: "540px",
  },
});

const tableCells = [
  { cell: "Persona" },
  { cell: "Vehículo" },
  { cell: "Tienda" },
  { cell: "Sucursal" },
  { cell: "Gasolina" },
  { cell: "Kilometraje" },
  { cell: "Factura" },
  { cell: "Cantidad" },
  { cell: "Fecha" },
];

// const handleSubmit=()=>{

// }

function Gasolina() {
  const classes = useStyle();
  const [isOpenNewVuelta, openNewVuelta, closeNewVuelta] = useModal();
  // const [validarOpciones, setValidarOpciones] = useState();

  const { tiendas } = useTiendas();
  const { vueltas } = useVueltas();
  const abrirNewVuelta = () => {
    openNewVuelta();
  };

  const tienda = [...new Set(tiendas.map((store) => store.tiendas))];
  // console.log(tienda);
  const [sucursal, setSucursal] = useState([]);
  const filterSucursal = (store) => {
    const filtro = tiendas.filter((fill) => fill.tiendas === store);
    const substores = [...new Set(filtro.map((sub) => sub.sucursales))];
    setSucursal(substores);
  };

  /* ARRAY DONDE RECIBO EL DATO NUEVO A AGREGAR */
  const [newVuelta, setNewVuelta] = useState({
    nombre: "",
    vehiculo: "",
    tienda: "",
    gasolina: "",
    kilometraje: "",
    factura: "",
    cantidad: "",
    fecha: "",
  });

  /* RECIBO LOS VALORES DE CADA INPUT Y/O DROPDOWN PARA MANDARLO A MI 
     ARRAY ANTES DECLARADO */
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setNewVuelta((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [sucursalSelect, setSucursalSelect] = useState([]);
  const handleChangeSucu = (e) => {
    setSucursalSelect(e);
    console.log(sucursalSelect);
  };

  // ACCION PARA ENVIAR LOS DATOS INCLUIDOS EN EL ARRAY
  const enviarNuevosDatos = async () => {
    var form = new FormData();
    form.append("nombre", newVuelta.nombre);
    form.append("vehiculo", newVuelta.vehiculo);
    form.append("tiendas", newVuelta.tienda);
    // form.append("sucursal", sucursal);
    form.append("gasolina", newVuelta.gasolina);
    form.append("kilometraje", newVuelta.kilometraje);
    form.append("factura", newVuelta.factura);
    form.append("cantidad", newVuelta.cantidad);
    form.append("fecha", newVuelta.fecha);
    await Database.post("/vueltas.php", form).then((respone) => {
      vueltas.concat(respone.vueltas);
      alert("El codigo ha sido agregado correctamente.");
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const count = vueltas.length;

  return (
    <div className="bgpage">
      <Navegacion />
      <div>
        <header className={classes.header}>
          <h3 className={classes.title}>Vueltas</h3>
          <div className={classes.bar}>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              className={classes.btn}
              onClick={abrirNewVuelta}
            >
              Nueva Vuelta
            </Button>
            <Modal
              show={isOpenNewVuelta}
              handleClose={closeNewVuelta}
              title="Agregar nueva vuelta"
              clase="modal-main"
            >
              <input
                placeholder="Nombre"
                className={classes.input}
                name="nombre"
                onChange={handleChanges}
              ></input>
              <input
                placeholder="Vehiculo"
                name="vehiculo"
                className={classes.input}
                style={{ width: 10 }}
                onChange={handleChanges}
              ></input>
              {/* <div>
              <label className={classes.label}>Gasolina</label>
              <label className={classes.label1}>Cantidad</label>
              </div> */}
              <input
                placeholder="$ Gasolina"
                name="gasolina"
                className={classes.input2}
                style={{ width: 10 }}
                onChange={handleChanges}
              ></input>

              <input
                placeholder="Kilometraje"
                name="kilometraje"
                className={classes.input2}
                style={{ width: 10 }}
                onChange={handleChanges}
              ></input>
              <input
                placeholder="Fecha"
                name="fecha"
                className={classes.input2}
                style={{ width: 10 }}
                onChange={handleChanges}
              ></input>
              <input
                placeholder="$ Cantidad"
                name="cantidad"
                className={classes.input2}
                style={{ width: 10 }}
                onChange={handleChanges}
              ></input>
              <input
                placeholder="Factura"
                name="factura"
                className={classes.input}
                style={{ width: 775 }}
                onChange={handleChanges}
              ></input>
              <Dropdown
                txtvalor="Tienda"
                nameOne="tienda"
                valores={tienda}
                subvalores={sucursal}
                txtsubvalor="Sucursal"
                nameTwo="sucursal"
                filterOpciones={filterSucursal}
                handleChanges={handleChanges}
              />
              <Button
                align="rigth"
                className={classes.btnsend}
                color="default"
                startIcon={<Icon>send</Icon>}
                onClick={enviarNuevosDatos}
              >
                Enviar
              </Button>
            </Modal>
          </div>
        </header>
        <Tablehook
          tableCells={tableCells}
          vueltas={vueltas}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangeSucu={handleChangeSucu}
        />
      </div>
      <TablePagination
        className={classes.tablepage}
        rowsPerPageOptions={[10]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

export default Gasolina;
