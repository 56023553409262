import {
  makeStyles,
  InputLabel,
  FormControl,
  Select,
  Button,
  Icon,
} from "@material-ui/core";

import React, { useRef, useState } from "react";

// import { useCodigos } from "../../hooks/useDatabase";
// import postCodigos from "../../services/methods/Method_Codigos_Post";
import postCodigos from "../../../services/methods/Method_Codigos_Post";
import { useCodigos } from "../../../hooks/useDatabase";

const useStyles = makeStyles((theme) => ({
  inputlabel: {
    marginLeft: 6,
    minWidth: 150,
    minHeight: 40,
    marginTop: 20,
    marginBottom: 8,
    width: "99.2%",
  },
  drop: {
    marginLeft: "25%",
    width: "50%",
    marginTop: "30px",
  },
  text: {
    color: "white",
    fontSize: "30px",
    marginLeft: "43.5%",
  },
  btnsend: {
    position: "center",
    marginLeft: "38%",
    marginTop: "50px",
    backgroundColor: "#bdc3c7",
    width: "200px",
    color: "black",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
  },
}));

function NewCode() {
  /* CONSTANTES PARA MIS REF */
  const RefCodigo = useRef(null);
  const RefNombre = useRef(null);
  const RefCantidad = useRef(null);
  const RefLinea = useRef(null);

  const classes = useStyles();
  const { datos } = useCodigos();

  const categorias = [...new Set(datos.map((filter) => filter.categoria))];

  /* ARRAY DONDE RECIBO EL DATO NUEVO A AGREGAR */
  const [newCodigo, setNewCodigo] = useState({
    categoria: "",
    linea: "",
    codigo: "",
    producto: "",
    cantidad: "",
  });

  /* RECIBO LOS VALORES DE CADA INPUT Y/O DROPDOWN PARA MANDARLO A MI 
   ARRAY ANTES DECLARADO */
  const handleChanges = (e, val) => {
    const { name, value } = e.target;
    setNewCodigo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // ACCION PARA ENVIAR LOS DATOS INCLUIDOS EN EL ARRAY
  const enviarNuevosDatos = async () => {
    var form = new FormData();
    form.append("categoria", newCodigo.categoria);
    form.append("linea", newCodigo.linea);
    form.append("codigo", newCodigo.codigo);
    form.append("producto", newCodigo.producto);
    form.append("cantidad", newCodigo.cantidad);

    // console.log(form);
    await postCodigos(form);
    RefLinea.current.value = "";
    RefCodigo.current.value = "";
    RefNombre.current.value = "";
    RefCantidad.current.value = "";
    // await Database.post("/index.php", form).then((respone) => {
    //   datos.concat(respone.datos);
    //   RefCodigo.current.value = "";
    //   RefNombre.current.value = "";
    //   RefCantidad.current.value = "";
    //   alert("El codigo ha sido agregado correctamente.");
    // });
  };

  return (
    <div className={classes.drop}>
      {/* ----------------- Categoria ----------------- */}
      <label className={classes.text}>Categoria</label>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel name="valores" htmlFor="grouped-native-select">
            Categoria
          </InputLabel>
          <Select
            name="categoria"
            native
            defaultValue=""
            id="grouped-native-select"
            onChange={handleChanges}
          >
            <option aria-label="None" value={-1}>
              Seleccionar opción
            </option>
            {categorias.map((item, i) => {
              return (
                <option value={item} key={"valores" + i}>
                  {item}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
      {/* ----------------- Linea ----------------- */}
      <label className={classes.text}>Linea</label>
      <input
        ref={RefLinea}
        name="linea"
        className={classes.inputlabel}
        onChange={handleChanges}
        placeholder="e.j. MASTERCHEF"
      ></input>
      {/* ----------------- INPUT CODIGO ----------------- */}
      <label className={classes.text}>Codigo</label>
      <input
        ref={RefCodigo}
        name="codigo"
        className={classes.inputlabel}
        onChange={handleChanges}
        placeholder="e.j. 7501076100029"
      ></input>
      {/* ----------------- INPUT PRODUCTO ----------------- */}
      <label className={classes.text}>Nombre</label>
      <input
        className={classes.inputlabel}
        placeholder="e.j. PIMIENTA 50 gr 12pz"
        name="producto"
        ref={RefNombre}
        onChange={handleChanges}
      ></input>
      {/* ----------------- INPUT CANTIDAD ----------------- */}
      <label className={classes.text}>Cantidad</label>
      <input
        ref={RefCantidad}
        name="cantidad"
        className={classes.inputlabel}
        placeholder="e.j. 150"
        onChange={handleChanges}
      ></input>
      {/* ------------- BOTON PARA ENVIAR DATOS ------------- */}
      <Button
        className={classes.btnsend}
        onClick={enviarNuevosDatos}
        startIcon={<Icon>send</Icon>}
      >
        Agregar
      </Button>
    </div>
  );
}

export default NewCode;
