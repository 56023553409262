import React, { useRef, useState } from "react";
import {
  makeStyles,
  Icon,
  Button,
  InputLabel,
  FormControl,
  Select,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import Dropdowns from "../../../componentes/Dropdowns";

import { useCodigos } from "../../../hooks/useDatabase";
import useModal from "../../../hooks/useModal";
import Modal from "../../../componentes/ventanamodal";
import { EditModal } from "../SubViews/EditCodeModal";
// import Rows from "../subcontainer/tablecontainrow";
// import Rows from "../../../containers/container/subcontainer/tablecontainrow"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
    marginLeft: 17,
  },
  headtable: {
    color: "yellow",
    fontVariantCaps: "all-small-caps",
    fontSize: "20px",
    textAlign: "left",
    borderBottom: "2px solid #c31432",
  },
  tblescosa: {
    textAlign: "center",
    maxWidth: "98%",
    maxHeight: "720px",
    marginLeft: "1%",
  },
  divmaster: {
    marginLeft: "50px",
    width: "80vw",
  },
  bodytable: {
    color: "white",
    fontSize: "18px",
    fontVariantCaps: "all-small-caps",
    textAlign: "left",
    tabSize: "small",
  },
}));

function EditCode() {
  const [page, setPage] = useState(0);
  const [rowpage] = useState(0);

  const classes = useStyles();

  const [isOpenModal, openModal, closeModal] = useModal();

  /* MANDO A LLAMAR MIS DATOS DE LA BASE DE DATOS CON useCodigos */
  const { datos } = useCodigos();

  /* DEFINO MIS CATEGORIAS */
  const linea = [...new Set(datos.map((filtro) => filtro.linea))];
  // console.log(linea);
  const [handleLinea, setHandleLinea] = useState("");

  const handleChanges = (e, val) => {
    setHandleLinea(e.target.value);
  };

  const [selectedCodes, setSelectCodes] = useState([]);

  const onClickSelect = (codigo) => {
    const code = datos.filter((filtro) => filtro.codigo === codigo);
    setSelectCodes(code);
    openModal();
  };

  const codigos = datos.filter((filtro) => filtro.linea === handleLinea);
  // console.log(codigos);
  return (
    <div className={classes.divmaster}>
      <h3
        style={{ color: "white", marginLeft: 570, marginTop: 15, fontSize: 34 }}
      >
        Editar Codigo
      </h3>

      <div>
        <div style={{ display: "flex" }}>
          <FormControl className={classes.formControl}>
            <InputLabel name="valores" htmlFor="grouped-native-select">
              Linea
            </InputLabel>
            <Select
              name="linea"
              native
              defaultValue=""
              id="grouped-native-select"
              onChange={handleChanges}
            >
              <option aria-label="None" value={-1}>
                Seleccionar opción
              </option>
              {linea.map((item, i) => {
                return (
                  <option value={item} key={"valores" + i}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <Modal
            show={isOpenModal}
            handleClose={closeModal}
            title="Edicion"
            clase="modal-moves"
          >
            <EditModal codigo={selectedCodes} handleClose={closeModal} />
          </Modal>
          <Button
            // onClick={() => abrirDeleteWindow()}
            style={{ height: 48, marginTop: 7 }}
            variant="contained"
            color="default"
            startIcon={<Icon>search</Icon>}
          ></Button>
        </div>
        <TableContainer className={classes.tblescosa}>
          <Table stickyHeader aria-label="customized table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  style={{ backgroundColor: "#3a6073" }}
                  className={classes.headtable}
                ></TableCell>
                <TableCell
                  style={{ backgroundColor: "#3a6073" }}
                  className={classes.headtable}
                >
                  Codigo
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#3a6073" }}
                  className={classes.headtable}
                >
                  Producto
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#3a6073" }}
                  className={classes.headtable}
                >
                  Cantidad
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {(rowpage > 0
                  ? codigos.slice(page * rowpage, page * rowpage + rowpage)
                  : codigos
                ).map((row) => (
                  <TableRow key={row.producto}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.bodytable}
                    >
                      <Button
                        name="codigo"
                        onClick={() => onClickSelect(row.codigo)}
                        variant="outlined"
                        style={{ color: "yellow" }}
                        size="small"
                      >
                        <Icon>edit</Icon>
                      </Button>
                    </TableCell>
                    <TableCell className={classes.bodytable}>
                      {row.codigo}
                    </TableCell>
                    <TableCell className={classes.bodytable}>
                      {row.producto}
                    </TableCell>
                    <TableCell className={classes.bodytable}>
                      {row.cantidad}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default EditCode;
