import React from "react";
import {
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import TableGastosRow from "../container/subcontainer/tablegastosrow";

const useTableStyle = makeStyles({
  tbl: {
    textAlign: "center",
    maxWidth: "98%",
    maxHeight: "720px",
    marginLeft: "1%",
  },
  headtable: {
    color: "yellow",
    fontVariantCaps: "all-small-caps",
    fontSize: "20px",
    textAlign: "center",
    borderBottom: "2px solid #c31432",
  },
  divdowntable: {
    marginTop: 15,
    color: "white",
    display: "absolute",
  },
  count: {
    position: "sticky",
    left: "35px",
    marginLeft: "50%",
    right: "70px",
  },
  page: {
    position: "fixed",
    left: "20px",
  },
  tablepage: {
    left: "20px",
    color: "white",
    marginTop: -42,
    position: "sticky",
  },
  head: {
    borderBottom: "2px solid #414345",
    backgroundColor: "#3a6073",
  },
});

function Tablegastos({ tableCells }) {
  const classes = useTableStyle();
  return (
    <div>
      <TableContainer className={classes.tblescosa}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.head}>
            <TableRow>
              {tableCells.map((cells) => (
                <TableCell
                  style={{ backgroundColor: "#3a6073" }}
                  className={classes.headtable}
                >
                  {cells.cell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableGastosRow />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Tablegastos;
