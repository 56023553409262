import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#870000",
    "&:hover": {
      backgroundColor: "#6f0000",
    },
    display: "flex",
    width: "80%",
    marginBottom: "3px",
    color: "white",
    borderRadius: "90px",
  },
});

function Filterdata({ checkbox, filterdata }) {
  const classes = useStyles();
  return (
    <div align="center">
      {checkbox.map((cat, i) => {
        return (
          <Button
            key={cat}
            className={classes.button}
            type="button"
            onClick={() => filterdata(cat)}
          >
            {cat}
          </Button>
        );
      })}
    </div>
  );
}

export default Filterdata;
