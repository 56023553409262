import React, { useState, useRef } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useCodigos } from "../../hooks/useDatabase";

import "../../styles/phone.css";
import MethodsPhone from "../../containers/methods/MethodsPhone";
import NavPhone from "../../componentes/NavPhone";

function Phone_Moves(props) {
  const useStyles = makeStyles({
    select: {
      display: "flex",
    },
  });
  const { datos } = useCodigos();
  const [category, setCategory] = useState("");
  const categoria = [...new Set(datos.map((e) => e.categoria))];
  //console.log(categoria);
  const data = datos.filter((a) => a.categoria === category);
  //console.log(data);
  const classes = useStyles();

  const [btnEntrada, btnSetEntrada] = useState();
  const [btnSalida, btnSetSalida] = useState();
  const [inputOrden, setInputOrden] = useState(true);
  const [btnActive, setBtnActive] = useState("");
  const [showCategory, setShowCategory] = useState(false);
  const [showCodeForm, setShowCodeForm] = useState(false);

  const showHiddenClass = showCategory ? "display-bloc" : "display-none";

  const ordenRef = useRef("");
  const [numOrden, setNumOrden] = useState({
    num_orden: "",
  });

  const handleEntrada = () => {
    btnSetSalida(true);
    setInputOrden(false);
    setBtnActive("Entrada");
    setShowCategory(true);
  };

  const handleSalida = () => {
    btnSetEntrada(true);
    setInputOrden(false);
    setBtnActive("Salida");
    setShowCategory(true);
  };

  const handleCancelar = () => {
    btnSetSalida(false);
    btnSetEntrada(false);
    setInputOrden(true);
    setBtnActive("");
    setShowCategory(false);
    setCategory("");
    setShowCodeForm(false);

    // handleChanges((ordenRef.current.value = ""));
  };

  const handleValidar = (e) => {
    if (!numOrden.num_orden) {
      alert("numero de orden vacio");
    } else {
      setInputOrden(true);
      setShowCodeForm(true);
    }
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setNumOrden((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  /**
   * AQUI SE GENERA EL FUNCIONAMIENTO DEL INPUT DE LA PANTALLA
   */
  const [selectKeyword, setSelectKeyword] = useState("");
  const [search, setSearch] = useState([]);
  const searchCodigo = (selected) => {
    setSelectKeyword(selected);
    if (selected !== "") {
      const searchList = data.filter((rows) => rows.codigo === selected);
      setSearch(searchList);
    }
  };

  return (
    <div className="contentmaster">
      <div className="phonediv">
        <NavPhone />

        <h1 className="txtTitle">Entradas y Salidas</h1>
      </div>
      <div className="phonediv2">
        <Button
          variant="contained"
          disabled={btnEntrada}
          style={{ marginLeft: 10 }}
          onClick={handleEntrada}
        >
          Entrada
        </Button>
        <Button
          variant="contained"
          disabled={btnSalida}
          style={{ marginLeft: 10 }}
          onClick={handleSalida}
        >
          Salida
        </Button>
      </div>
      <div className={showHiddenClass}>
        {categoria.map((a) => {
          return (
            <Button
              onClick={() => setCategory(a)}
              variant="contained"
              style={{ marginLeft: "2%", marginTop: "5%" }}
              color={category === a ? "primary" : "default"}
            >
              {a}
            </Button>
          );
        })}
      </div>

      {category !== "" ? (
        <div className="inputdiv">
          <input
            style={{ color: "red" }}
            name="num_orden"
            ref={ordenRef}
            disabled={inputOrden}
            placeholder={btnActive === "Entrada" ? "Num. de guia" : "Factura"}
            onChange={handleChanges}
          ></input>
          <Button
            variant="contained"
            color="primary"
            disabled={inputOrden}
            style={{ marginLeft: 10, height: 30, marginTop: -5 }}
            onClick={handleValidar}
          >
            Validar
          </Button>
        </div>
      ) : (
        <div></div>
      )}

      <div className="codediv">
        <section>
          {showCodeForm ? (
            <div className={classes.select}>
              <MethodsPhone
                category={category}
                title={btnActive}
                selectKeyword={selectKeyword}
                searchCodigo={searchCodigo}
                search={search}
                numOrden={numOrden}
                handleCancelar={handleCancelar}
                data={data}
                key={data.codigo}
              />
            </div>
          ) : (
            <div></div>
          )}
        </section>
      </div>
    </div>
  );
}

export default Phone_Moves;
