import React from "react";
import { TableBody, TableRow, TableCell, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  cellColor: {
    color: "black",
    width: "20px",
  },
});

function HistoryBody({ llave, elemento, detailsShown }) {
  const classes = useStyles();

  return (
    <TableBody>
      {detailsShown.includes(elemento.linea) && (
        <TableRow key={llave}>
          {/* <TableCell className={classes.cellColor}>{elemento.linea}</TableCell> */}
          <TableCell className={classes.cellColor}>
            {elemento.sucursal}
          </TableCell>
          <TableCell className={classes.cellColor}>{elemento.codigo}</TableCell>
          <TableCell className={classes.cellColor}>
            {elemento.producto}
          </TableCell>
          <TableCell align="center" className={classes.cellColor}>
            {elemento.cantidad}
          </TableCell>
          <TableCell align="center" className={classes.cellColor}>
            {elemento.num_orden}
          </TableCell>
          <TableCell align="center" className={classes.cellColor}>
            {elemento.fecha}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default HistoryBody;
