import React, { Component } from "react";
import "../styles/exportstyles.css";
import { Button } from "@material-ui/core";
import ReactExport from "react-export-excel";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
registerLocale("es", es);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelSheet;
const ExcelColumn = ReactExport.ExcelColumn;

class ExportExcelDate extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    desde: new Date(),
    hasta: new Date(),
    dataFilter: [],
    codigosFilter: [],
    isOpenValidar: false,
    disableDate: false,
    stringDesde: "",
    stringHasta: "",
  };

  handleChangeFrom = (from) => {
    this.setState({ desde: from });
    this.setState({ isOpenValidar: false });
    this.setState({ disableDate: false });
  };

  handleChangeTo = (to) => {
    this.setState({ hasta: to });
    this.setState({ isOpenValidar: false });
    this.setState({ disableDate: false });
  };

  handleValidar = () => {
    const desdeFormat = moment(this.state.desde).format("YYYY-MM-DD");
    const hastaFormat = moment(this.state.hasta).format("YYYY-MM-DD");
    this.setState({
      dataFilter: this.props.datos.filter(
        (rows) => rows.fecha >= desdeFormat && rows.fecha <= hastaFormat
      ),
    });

    this.setState({ isOpenValidar: true });
    this.setState({ disableDate: true });
    this.setState({
      stringDesde: moment(this.state.desde).format("DD-MMM-YYYY"),
      stringHasta: moment(this.state.hasta).format("DD-MMM-YYYY"),
    });
  };

  handleCloseValidar = () => {
    this.setState({ isOpenValidar: false });
    this.setState({ disableDate: false });
  };
  render() {
    return (
      <div>
        <div className="columDiv">
          <div align="center">
            <p>Desde</p>
            <DatePicker
              onChange={this.handleChangeFrom}
              placeholderText="DESDE"
              isClearable
              selected={this.state.desde}
              locale="es"
              className="pickers"
              dateFormat="yyyy - MM - dd"
              disabled={this.state.disableDate}
            />
          </div>
          <div align="center">
            <p>Hasta</p>
            <DatePicker
              onChange={this.handleChangeTo}
              placeholderText="HASTA"
              selected={this.state.hasta}
              locale="es"
              className="pickers"
              dateFormat="yyyy - MM - dd"
              isClearable
              disabled={this.state.disableDate}
            />
          </div>
        </div>
        <div
          className={!this.state.isOpenValidar ? "btnDiv" : "btnDiv2"}
          align="center"
        >
          {!this.state.isOpenValidar && (
            <Button
              onClick={this.handleValidar}
              variant="outlined"
              color="inherit"
            >
              validar
            </Button>
          )}
        </div>

        {this.state.isOpenValidar && (
          <div>
            <br></br>
            <p className="ptxt" align="center">
              Estas seguro que quieres exportar los datos de la fecha
            </p>
            <p align="center">
              {this.state.stringDesde} hasta &nbsp;{this.state.stringHasta}
            </p>
            <p>{this.state.desde.toLocaleDateString}</p>
            <div className="columDiv2">
              <Button
                onClick={this.handleCloseValidar}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
              <ExcelFile
                element={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleCloseValidar}
                  >
                    Descargar Archivo
                  </Button>
                }
                filename="Archivo de movimiento"
              >
                <ExcelSheet
                  data={this.state.dataFilter}
                  name="Historial de Movimientos"
                >
                  <ExcelColumn label="" value="" />
                  <ExcelColumn label="Movimiento" value="movimiento" />
                  <ExcelColumn label="Sucursal" value="sucursal" />
                  <ExcelColumn label="Linea" value="linea" />
                  <ExcelColumn label="Codigo" value="codigo" />
                  <ExcelColumn label="Producto" value="producto" />
                  <ExcelColumn label="Cantidad" value="cantidad" />
                  <ExcelColumn label="Fecha" value="fecha" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
        )}
      </div>
    );
  }
}
//
export default ExportExcelDate;
