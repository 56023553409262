import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navegacion from "../componentes/Navbar";
import "../styles/acsstyle.css";
import { makeStyles, Button } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ExportExcelDate from "../services/exportexcelDate";
import HistoryTable from "../containers/historytable/tablehistory";
// import TableHistory from "../componentes/tablehistory";
import Searchbar from "../componentes/Searchbar";
import useModal from "../hooks/useModal";
import Modal2 from "../componentes/Modal";
import Modal from "../componentes/ventanamodal";
import TableHistory from "../containers/historytable/historysearch/tablehisearch";
import { useHistory, useCodigos } from "../hooks/useDatabase";
import { Icon } from "@material-ui/core";
import { Category } from "@material-ui/icons";
import Menu from "../componentes/Menu";

const useStyle = makeStyles({
  header: {
    display: "flex",
  },
  title: {
    fontSize: "50px",
    letterSpacing: "10px",
    color: "white",
    fontFamily: "",
    fontVariantCaps: "all-small-caps",
    marginLeft: 20,
  },
  bar: {
    position: "absolute",
    marginLeft: "75%",
    display: "flex",
    marginTop: "25px",
  },
  filter: {
    height: "33px",
    marginRight: "5px",
  },
  btnLineas: {
    color: "black",
    width: "80%",
    marginBottom: "3px",
    borderRadius: "90px",
    backgroundColor: "#c0c0aa",
    "&:hover": {
      backgroundColor: "#FAFFD1",
    },
  },
  btnLineasActive: {
    color: "white",
    width: "80%",
    marginBottom: "3px",
    borderRadius: "90px",
    display: "flex",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#FAFFD1",
      color: "red",
    },
  },
  btnBlock: {
    color: "black",
    borderRadius: "90px",
    marginBottom: "3px",
    width: "80%",
    display: "flex",
    backgroundColor: "#c0c0aa",
    "&:hover": {
      backgroundColor: "#FAFFD1",
    },
    height: "35px",
  },
  btnBlockActive: {
    borderRadius: "90px",
    color: "white",
    marginBottom: "3px",
    width: "80%",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#FAFFD1",
      color: "red",
    },
    height: "35px",
  },
});

function Inventario() {
  const [isOpenSearchis, openSearchis, closeSearchis] = useModal();
  const [isOpenImportar, openImportar, closeImportar] = useModal();
  const [isOpenCategory, openCategory, closeCategory] = useModal();

  const { history } = useHistory();
  console.log(history);
  const { datos } = useCodigos();

  const classes = useStyle();

  const lineasHistorial = [...new Set(history.map((a) => a.linea))];
  const movesHistorial = [...new Set(history.map((h) => h.movimiento))];

  /**   BUSCADOR DE LA TABLA HISTORIAL  **/
  const [searchIdResult, setSearchIdResult] = useState([]);
  const [searchId, setSearchId] = useState("");
  const searchIdNumber = (ids) => {
    setSearchId(ids);
    if (ids !== "") {
      history.map((a) => {
        if (ids === a.num_orden) {
          const searchOrden = history.filter((b) => b.num_orden === ids);
          setSearchIdResult(searchOrden);
          setbtnMoves(false);
        }
        if (ids === a.fecha) {
          const searchFecha = history.filter((c) => c.fecha === ids);
          setSearchIdResult(searchFecha);
          setbtnMoves(false);
        }
        if (ids === a.codigo) {
          const searchCode = history.filter((c) => c.codigo === ids);
          setSearchIdResult(searchCode);
          setbtnMoves(true);
        }
      });
    }
  };

  const handleCleanArray = () => {
    closeSearchis();
    setSearchIdResult([]);
  };

  /**
   * FILTRO DE LINEAS
   */

  const [filterResults, setFilterResults] = useState(history);
  const [detailsShown, setDetailsShown] = useState([]);
  const shownState = detailsShown.slice();

  const [open, setOpen] = useState();
  const filterdata = (filtro) => {
    if (filtro === "hide") {
      setOpen(false);
    } else {
      const filteredData = history.filter(
        (historial) => historial.linea === filtro
      );
      setFilterResults(filteredData);
      setOpen(true);
    }
  };

  const [activeLine, setActiveLine] = useState(lineasHistorial[0]);
  const openFiltrar = (reciblinea) => {
    setActiveLine(reciblinea);
    filterdata(reciblinea);
    const index = shownState.indexOf(reciblinea);
    if (index >= 0) {
      // shownState.splice(index, 1);
      setDetailsShown(shownState);
    } else {
      shownState.push(reciblinea);
      setDetailsShown(shownState);
    }
    closeCategory();
  };

  /**
   * FILTRO DE MOVIMIENTOSs
   */
  const [btnMoveActive, setbtnMoveActive] = useState("");
  const [btnSearchActive, setbtnSearchActive] = useState("");

  const historyFilter = (recibmoves) => {
    const lineaFilter = history.filter((l) => l.linea === activeLine);
    const filtradoMoves = lineaFilter.filter(
      (a) => a.movimiento === recibmoves
    );
    setFilterResults(filtradoMoves);
    setbtnMoveActive(recibmoves);
  };

  const [btnMoves, setbtnMoves] = useState(false);
  const btnEntradaSalida = btnMoves
    ? "btnmoves display-block"
    : "btnmoves display-none";

  const searchFilter = (moves) => {
    searchIdResult.map((a) => {
      if (moves === "todo") {
        const moveTodo = history.filter((c) => c.codigo === a.codigo);
        setSearchIdResult(moveTodo);
        setbtnSearchActive(moves);
      } else {
        const searchMoveCode = history.filter((b) => b.codigo === a.codigo);
        const searchMove = searchMoveCode.filter((e) => e.movimiento === moves);
        setSearchIdResult(searchMove);
        setbtnSearchActive(moves);
      }
    });
  };

  return (
    <div className="bgpage">
      <Navegacion />
      <div id="inventario">
        <header className={classes.header}>
          <Modal2
            show={isOpenCategory}
            handleClose={closeCategory}
            clase="category-his-filter"
            title="Categorias"
          >
            <div className="category-filter-his">
              <div align="center">
                <Button
                  size="medium"
                  className={
                    activeLine === "hide"
                      ? classes.btnBlockActive
                      : classes.btnBlock
                  }
                  onClick={() => openFiltrar("hide")}
                >
                  Limpiar
                </Button>
                <div className="category-button">
                  {lineasHistorial.map((a) => {
                    return (
                      <Button
                        variant="outlined"
                        onClick={() => openFiltrar(a)}
                        className={
                          activeLine === a
                            ? classes.btnLineasActive
                            : classes.btnLineas
                        }
                      >
                        {a}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
          </Modal2>
          <Button
            onClick={openCategory}
            // startIcon={<Icon>menu</Icon>}
            startIcon={<box-icon name="menu" color="white"></box-icon>}
            variant="contained"
            style={{
              background: "black",
              color: "white",
              height: 35,
              marginTop: 15,
            }}
          ></Button>
          <h3 className={classes.title}>Historial</h3>

          <div className={(classes.bar, "btnhistorypage")}>
            {/* ----------------- BOTON DE MODAL EXPORTAR ----------------- */}
            <Modal
              show={isOpenImportar}
              handleClose={closeImportar}
              title="Exportar"
              clase="exportDate-modal"
            >
              <ExportExcelDate datos={history} complementData={datos} />
            </Modal>
            <Button
              type="button"
              onClick={openImportar}
              variant="contained"
              style={{ color: "black", background: "yellow" }}
              size="small"
              className="btnExcelPage"
              // endIcon={<ImportExportIcon />}
              endIcon={<box-icon name="export" color="black"></box-icon>}
            >
              Exportar
            </Button>
            {/* ----------------- BOTON DE MODAL SEARCH ----------------- */}

            <Searchbar
              shows={isOpenSearchis}
              show={openSearchis}
              handleClose={openSearchis}
              searchKeyword={searchIdNumber}
              term={searchId}
            />
            <Modal
              show={isOpenSearchis}
              handleClose={handleCleanArray}
              title="Busqueda - Historial"
              clase="modal-moves"
            >
              <TableHistory
                history={searchIdResult}
                movesHistorial={movesHistorial}
                searchFilter={searchFilter}
                btnEntradaSalida={btnEntradaSalida}
                factura={btnSearchActive === "Entrada" ? "N. Guia" : "Factura"}
              />
            </Modal>
          </div>
        </header>

        {activeLine && (
          <HistoryTable
            history={filterResults}
            lineas={activeLine}
            detailsShown={detailsShown}
            open={open}
            movesHistorial={movesHistorial}
            historyFilter={historyFilter}
            factura={btnMoveActive === "Entrada" ? "N. Guia" : "Factura"}
          />
        )}
      </div>
    </div>
  );
}

export default Inventario;
