import { useState } from "react";

export const useError = (inicialValue = false) => {
  const [isOpenError, setIsOpenError] = useState(inicialValue);

  const openError = () => {
    setIsOpenError(true);
  };

  const closeError = () => {
    setIsOpenError(false);
  };

  return [isOpenError, openError, closeError];
};

export const useError_getNull = () => {
  alert("Agrega un codigo para buscar");
  return alert;
};

export const useError_noFound = () => {
  alert("Codigo no encontrado");
  return alert;
};

export const useError_noRepeat = () => {
  alert("Codigo ya existe");
  return alert;
};

export const useError_noExistencia = () => {
  alert("No hay suficiente existencia");
  return alert;
};
