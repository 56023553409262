import React, { useRef } from "react";
import { Icon, makeStyles, Button } from "@material-ui/core"; //TextField InputLabel

const useStyles = makeStyles({
  search: {
    display: "flex",
    transition: "display 3000ms ease",
  },
});
// props... handleClose, show, clase, term, searchKeyword
const Searchbar = (props) => {
  const showHideClassName = props.show
    ? "search display-block"
    : "search display-none";
  const classes = useStyles();
  const inputRef = useRef("");

  const getSearchTerm = () => {
    props.searchKeyword(inputRef.current.value);
  };

  const cleanInpu = (e) => {
    getSearchTerm((inputRef.current.value = ""));
    props.handleClose();
    return;
  };

  return (
    <div className={showHideClassName}>
      <section className={props.clase}>
        <div className={classes.search}>
          <input
            ref={inputRef}
            name="busqueda"
            type="text"
            placeholder="Buscar"
            value={props.term}
            onChange={getSearchTerm}
            className={props.clase}
            autoFocus={props.autoFocus}
          ></input>
          <Button
            onClick={() => cleanInpu()}
            variant="contained"
            color="default"
            // startIcon={<Icon>search</Icon>}
            startIcon={<box-icon name="search"></box-icon>}
          ></Button>
        </div>
      </section>
    </div>
  );
};

export default Searchbar;
