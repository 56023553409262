import "bootstrap/dist/css/bootstrap.min.css";
import {
  Icon,
  Button,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import logo from "../img/logo1.png";
import { useRoles } from "../hooks/useDatabase";
import register from "../services/methods/Method_Register";
import Navegacion from "../componentes/Navbar";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
  },
}));

function Register() {
  const classes = useStyles();
  const { roles } = useRoles();
  const userrols = [...new Set(roles.map((item) => item.rol))];

  const [getUserData, setGetUserData] = useState({
    name: "",
    username: "",
    password: "",
    idRolUsuario: "",
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setGetUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(getUserData);
  };

  const handleLogin = async () => {
    await register(getUserData);
  };

  return (
    <div>
      <Navegacion />
      <main className="inicio">
        <header className="headerinicio d-flex">
          <div className="container text-center my-auto">
            <span className="hmb"> Formulario para nuevo usuario</span>{" "}
            <br></br>
          </div>
        </header>

        <div className="container p-4">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="card text-center">
                <div className="card-header">
                  {/* HEADER DE PAGINA INICIO  */}
                  <h3> Register</h3>
                  <img src={logo} alt="" className="img center my-auto" />
                </div>
                <div className="card-body">
                  {/* USUARIO - FORMATO DE VALIDACION */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <Icon className="iconformat" style={{ fontSize: 30 }}>
                          person
                        </Icon>
                      </span>
                      <input
                        name="name"
                        placeholder="Nombre"
                        className="us form-control"
                        aria-label="nombre"
                        aria-describedby="basic-addon1"
                        autoFocus
                        onChange={handleChanges}
                      />
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <Icon className="iconformat" style={{ fontSize: 30 }}>
                          person
                        </Icon>
                      </span>
                      <input
                        name="username"
                        placeholder="Usuario"
                        className="us form-control"
                        aria-label="usuario"
                        aria-describedby="basic-addon1"
                        autoFocus
                        onChange={handleChanges}
                      />
                    </div>
                  </div>
                  {/* CONTRASEÑA - FORMATO DE VALDACION */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon2">
                        <Icon className="iconformat" style={{ fontSize: 30 }}>
                          lock
                        </Icon>
                      </span>
                      <input
                        type="password"
                        name="password"
                        className="pw form-control"
                        placeholder="Contraseña"
                        aria-label="contraseña"
                        aria-describedby="basic-addon2"
                        onChange={handleChanges}
                      />
                    </div>
                  </div>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      name="idRolUsuario"
                      htmlFor="grouped-native-select"
                    >
                      Rol Asignado
                    </InputLabel>
                    <Select
                      name="idRolUsuario"
                      native
                      defaultValue=""
                      id="grouped-native-select"
                      onChange={handleChanges}
                    >
                      <option aria-label="None" value={-1}>
                        Seleccionar opción
                      </option>
                      {userrols.map((items, i) => (
                        <option value={i + 1} key={"valor" + i}>
                          {items}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    className="btninicio "
                    style={{
                      backgroundColor: "#E2E2E2",
                      fontSize: 20,
                      borderRadius: 10,
                      width: 250,
                    }}
                    startIcon={<Icon>doneall</Icon>}
                    onClick={handleLogin}
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
export default Register;
