import React, { useState } from "react";
import { makeStyles, InputLabel, FormControl, Select } from "@material-ui/core";
import MultipleSelect from "./MultipleSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
  },
}));

function Dropdown({
  txtvalor,
  valores,
  subvalores,
  txtsubvalor,
  filterOpciones,
  nameOne,
  nameTwo,
  handleChanges,
}) {
  const classes = useStyles();

  const [idOpciones, setIdOpciones] = useState(false);

  const handlerCargarOpciones = function (e) {
    const opcion = e.target.value;
    filterOpciones(opcion);
    setIdOpciones(true);
    console.log(idOpciones);
  };

  valores.map((item) => {
    const hey = [{ value: item, label: item }];
    return hey;
  });

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel name="valores" htmlFor="grouped-native-select">
          {txtvalor}
        </InputLabel>
        <Select
          name={nameOne}
          native
          defaultValue=""
          id="grouped-native-select"
          onClick={handlerCargarOpciones}
          onChange={handleChanges}
        >
          <option aria-label="None" value={-1}>
            Seleccionar opción
          </option>
          {valores.map((item, i) => {
            return (
              <option value={item} key={"valores" + i}>
                {item}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <MultipleSelect
        subvalores={subvalores}
        handleChanges={handleChanges}
        nameTwo={nameTwo}
        txtsubvalor={txtsubvalor}
      />

      {/* 
        <FormControl className={classes.formControl}>
          <InputLabel name="subvalor" htmlFor="grouped-native-select">
            {txtsubvalor}
          </InputLabel>
          <Select
            name={nameTwo}
            native
            defaultValue=""
            id="grouped-native-select"
            onChange={handleChanges}
            options={subvalores}
            
          >
            <option aria-label="None" value={-1}>
              Seleccionar opción
            </option>
             {idOpciones  && 
              subvalores.map((item, i) => {
                 return(
                    <option value={item} key={"subvalor" + i}>
                    {item}
                  </option>
             )})}               
          </Select>
        </FormControl> */}
    </div>
  );
}

export default Dropdown;
