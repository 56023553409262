import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Escosa from "../img/escosa.png";
import Asociados from "../img/A.png";
import Especias from "../img/especias.png";
import "../styles/main.css";
import Navegacion from "../componentes/Navbar";
import usuarios from "../hooks/usuarios";
import { Avatar, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useCodigos } from "../hooks/useDatabase";
import logo from "../img/logo1.png";
import Menu from "../componentes/Menu";

function Main() {
  /* RECIBO MIS DATOS DE LA BASE DE DATOS*/
  const { datos } = useCodigos();
  const [imagen, user] = usuarios();

  /* SELECCIONO SOLO LAS CATEGORIAS DE LOS DATOS */
  const categorias = [...new Set(datos.map((item) => item.categoria))];

  return (
    <div className="masterbg">
      {/* ----------------- BARRA DE NAVEGACION ----------------- */}

      <Menu />

      <div className="bienvenida">
        {/* ----------------- HEADERS ----------------- */}
        <header className="mastheadbd d-flex">
          <div className="titulo container text-center mx-auto">
            <span className="mb" style={{ fontSize: 62 }}>
              Productos y Servicios Dehesas{" "}
              <img
                src={logo}
                alt=""
                width="58"
                height="58"
                className="d-inline-block align-top"
                style={{ marginTop: 27 }}
              />
              <br></br>
            </span>
            <span className="mb-5" style={{ fontSize: 40 }}>
              <em>Sistema de control de inventario</em>
            </span>
          </div>
        </header>
        {/* ----------------- TXT BIENVENIDA CON AVATAR ----------------- */}
        {/* <div className="usuario  ">
          <h1 className=" mx-auto"> Bienvenido de vuelta "{user}" </h1>
          <Avatar className="avatar">{imagen}</Avatar>
        </div> */}

        <div className="row-images">
          {/* ----------------- <!-- MAPEO DE CATEGORIAS  -->----------------- */}
          {categorias.map((cat) => {
            return (
              <div className="images" key={cat}>
                <Button type="button">
                  <Link
                    to={{
                      pathname: `/tablepage`,
                      state: {
                        title: cat,
                      },
                    }}
                  >
                    <img
                      className="escosa"
                      width="300px"
                      height="150px"
                      src={
                        cat === "Escosa"
                          ? Escosa
                          : null || cat === "Asociados"
                          ? Asociados
                          : null || cat === "Especias"
                          ? Especias
                          : null
                      }
                      alt="imagen"
                    />
                  </Link>
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Main;
