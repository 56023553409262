import React, { useState } from "react";
import "boxicons";
import "../styles/menu.css";
import logo from "../img/logo1.png";

const Menu = () => {
  const [sidebar, setSidebar] = useState("sidebar");
  const hideSidebar = () => {
    setSidebar(sidebar === "sidebar" ? "sidebar active" : "sidebar");
  };

  return (
    <div className="menu_div">
      <div className={sidebar}>
        <div className="logo_content">
          <div className="logo">
            <img
              src={logo}
              alt=""
              width="30"
              height="30"
              className="d-inline-block align-top"
            />

            <div className="logo_name"> MENU </div>
          </div>
          <box-icon
            name="menu"
            id="btn"
            color="#fefaf6"
            onClick={hideSidebar}
          ></box-icon>
          <ul className="nav_list">
            <li>
              <a href="/main">
                <i>
                  <box-icon name="home" color="#fefaf6"></box-icon>
                </i>
                <span className="links_name">Inicio</span>
              </a>
              <span className="tool">Inicio</span>
            </li>
            <li>
              <a href="/historial">
                <i>
                  <box-icon name="history" color="#fefaf6"></box-icon>
                </i>
                <span className="links_name">Historial</span>
              </a>
              <span className="tool">Historial</span>
            </li>
            <li>
              <a href="/datehistory">
                <i>
                  <box-icon name="archive" color="#fefaf6"></box-icon>
                </i>
                <span className="links_name">Historial</span>
              </a>
              <span className="tool">Historial/Fecha</span>
            </li>
            <li>
              <a href="/methodpage">
                <i>
                  <box-icon
                    name="edit-alt"
                    type="solid"
                    color="#fefaf6"
                  ></box-icon>
                </i>
                <span className="links_name">Edicion</span>
              </a>
              <span className="tool">Edicion</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
