import { makeStyles, Button, Icon } from "@material-ui/core";
import React, { useRef } from "react";

const useStyles = makeStyles({
  select: {
    display: "flex",
  },
});

const Selectbar = (props) => {
  const showHiddenClass = props.show
    ? "select-modal display-block"
    : "select-modal display-none";

  const classes = useStyles();
  const inputSel = useRef("");

  const enterPressKey = (event) => {
    if (event.key === "Enter") {
      cleanInputSelect();
    }
  };

  const getSelectTerm = () => {
    props.searchCodigo(inputSel.current.value);
  };

  const cleanInputSelect = () => {
    props.searchCodigo(inputSel.current.value);
    getSelectTerm((inputSel.current.value = ""));
    props.handleAdd();
    // props.generateNewData(inputSel.current.value);
    return;
  };

  return (
    <div className={showHiddenClass} onKeyPress={(a) => enterPressKey(a)}>
      <section>
        <div className={classes.select}>
          <input
            ref={inputSel}
            name="select"
            type="text"
            className={props.clase}
            placeholder="Codigo"
            value={props.selectKeyword}
            onChange={getSelectTerm}
            autoFocus
          ></input>
          <Button
            onClick={() => cleanInputSelect()}
            variant="contained"
            color="default"
            startIcon={<box-icon name="search-alt-2"></box-icon>}
          ></Button>
        </div>
      </section>
    </div>
  );
};
export default Selectbar;
