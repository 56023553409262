import React, { useState } from "react";
import Menu from "../componentes/Menu";
import { useCodigos, useHistory } from "../hooks/useDatabase";
import {
  makeStyles,
  InputLabel,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Collapse,
  Typography,
} from "@material-ui/core";
import DatePicker, { registerLocale } from "react-datepicker";
import "boxicons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
    marginLeft: 17,
  },
}));

function Page_DateHistory() {
  const { datos } = useCodigos();
  const linea = [...new Set(datos.map((filtro) => filtro.linea))];

  const classes = useStyles();
  const { history } = useHistory();
  const lineasHistorial = [...new Set(history.map((a) => a.linea))];

  return (
    <div>
      <Menu />

      <div className="method" style={{ marginLeft: 80 }}>
        <FormControl className={classes.formControl}>
          <InputLabel name="valores" htmlFor="grouped-native-select">
            Linea
          </InputLabel>
          <Select
            name="linea"
            native
            defaultValue=""
            id="grouped-native-select"
            //   onChange={handleChanges}
          >
            <option aria-label="None" value={-1}>
              Seleccionar opción
            </option>
            {linea.map((item, i) => {
              return (
                <option value={item} key={"valores" + i}>
                  {item}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <div className="columnDateHistory">
          <div
            align="center"
            style={{ marginLeft: "40px", marginRight: "-40px" }}
          >
            <DatePicker
              //   onChange={this.handleChangeFrom}
              placeholderText="DESDE"
              isClearable
              //   selected={this.state.desde}
              locale="es"
              className="pickers"
              dateFormat="yyyy - MM - dd"
              //   disabled={this.state.disableDate}
            />
          </div>

          <p style={{ marginTop: "10px" }}>a</p>

          <div
            align="center"
            style={{ marginLeft: "-120px", marginRight: "-40px" }}
          >
            <DatePicker
              //   onChange={this.handleChangeTo}
              placeholderText="HASTA"
              //   selected={this.state.hasta}
              locale="es"
              className="pickers"
              dateFormat="yyyy - MM - dd"
              isClearable
              //   disabled={this.state.disableDate}
            />
          </div>
        </div>

        <Button
          startIcon={<box-icon name="search-alt-2"></box-icon>}
          color="primary"
          variant="contained"
          style={{ height: "30px", marginTop: "17px" }}
        >
          Buscar
        </Button>
      </div>

      <div className="method" style={{ marginLeft: 80 }}>
        <Table size="small" aria-label="purchases">
          <TableHead className={classes.headletter}>
            <TableRow>
              {/* key={props.lineas} */}
              <TableCell>Sucursal</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell align="center">Movimeinto</TableCell>
              <TableCell align="center">Orden</TableCell>
              {/* {props.factura} */}
              <TableCell align="center">Fecha</TableCell>
            </TableRow>
          </TableHead>
          {history.map((elementos) => (
            <TableBody>
              {/* {detailsShown.includes(elemento.linea) && ( */}
              <TableRow>
                {/* //key={llave} */}
                {/* <TableCell className={classes.cellColor}>{elemento.linea}</TableCell> */}
                <TableCell className={classes.cellColor}>
                  {/* {elemento.sucursal} */}
                </TableCell>
                <TableCell className={classes.cellColor}>
                  {/* {elemento.codigo} */}
                </TableCell>
                <TableCell className={classes.cellColor}>
                  {/* {elemento.producto} */}
                </TableCell>
                <TableCell align="center" className={classes.cellColor}>
                  {/* {elemento.cantidad} */}
                </TableCell>
                <TableCell align="center" className={classes.cellColor}>
                  {/* {elemento.num_orden} */}
                </TableCell>
                <TableCell align="center" className={classes.cellColor}>
                  {/* {elemento.fecha} */}
                </TableCell>
              </TableRow>
              {/* )} */}
            </TableBody>
          ))}
        </Table>
      </div>
    </div>
  );
}

export default Page_DateHistory;
