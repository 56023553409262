import React, { Component } from "react";
import MenuItem from "./MenuItem";
import "./Categorys.css";
import { Icon } from "@material-ui/core";

class MenuCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({
      visible: !this.state.visible,
    });
  }
  render() {
    return (
      <div className="category">
        <li>
          <h3 onClick={this.handleClick.bind(this)}>
            {this.state.visible ? (
              <span style={{ height: 25, marginRight: 10 }}>
                <box-icon
                  name="book"
                  color="white"
                  height="10px"
                  type="solid"
                ></box-icon>
              </span>
            ) : (
              <span style={{ height: 25, marginRight: 10 }}>
                <box-icon name="book-add" color="white" type="solid"></box-icon>
              </span>
            )}
            {this.props.name}
          </h3>
          <ul className={this.state.visible ? "visible" : "no-visible"}>
            {this.props.items.map((item) => {
              return (
                <MenuItem name={item} key={item} click={this.props.click} />
              );
            })}
          </ul>
        </li>
      </div>
    );
  }
}
export default MenuCategory;
