import {
  Icon,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  makeStyles,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import Row from "../modaltable/modaltablerow";
import Selectbar from "../../componentes/Selectbar";
import { useState } from "react";
import { Database } from "../../api/Database";
import {
  useError_getNull,
  useError_noFound,
  useError_noRepeat,
} from "../../hooks/useError";
import { useSurtimiento } from "../../hooks/useDatabase";

const useRowStyle = makeStyles({
  head: {
    color: "#dd1818",
    fontSize: "20px",
    fontVariantCaps: "all-small-caps",
  },
  headmaster: {
    position: "sticky",
    zIndex: 100,
  },
  rowscroll: {
    scrollBehavior: "smooth",
    maxHeight: "400px",
  },
  codcell: {
    color: "black",
  },
  lastdiv: {
    padding: "5px",
    display: "flex",
  },
  btncancel: {
    position: "end",
    marginLeft: "60%",
    marginRight: "2%",
  },
  btnsend: {
    position: "end",
  },
  totcods: {
    display: "flex",
    marginTop: "10px",
    marginLeft: "15px",
    fontSize: "12px",
  },
  inputorden: {
    position: "absolute",
    top: "39px",
    left: "20px",
    width: "120px",
    borderRadius: "3px",
    borderColor: "white",
  },
  inputorden_error: {
    position: "absolute",
    top: "39px",
    left: "20px",
    width: "120px",
    borderRadius: "3px",
    borderColor: "red",
  },
  formControl: {
    margin: 10,
    maxWidth: 180,
    minHeight: 20,
    display: "flex",
    marginBottom: 10,
    marginTop: -55,
    marginLeft: 150,
  },
  formControl2: {
    margin: 10,
    maxWidth: 180,
    minHeight: 20,
    display: "flex",
    marginBottom: 10,
    marginTop: -90,
    marginLeft: 150,
    marginBottom: 30,
  },
});

function TableContainModal(props) {
  const getNull = useError_getNull;
  const noFound = useError_noFound;
  const noRepeat = useError_noRepeat;
  const classes = useRowStyle();
  const openSelectBar = useState(true);

  const { sucursal } = useSurtimiento();

  // ALMACENAJE DE CODIGOS
  const [selectResults, setSelectResults] = useState([]);
  // RECIBE EL CODIGO Y VERIFICA SI NO ESTA REPETIDO
  const agregarCod = () => {
    if (
      !selectResults.find((selected) => {
        return selected.codigo === props.search[0].codigo;
      })
    ) {
      const newResults = props.search.map((selectecod) => {
        return {
          codigo: selectecod.codigo,
          producto: selectecod.producto,
          linea: selectecod.linea,
          cant: selectecod.cantidad,
          cantidad: "",
        };
      });
      setSelectResults([...selectResults, ...newResults]);
      console.log(selectResults);
    } else {
      noRepeat();
    }
  };

  /* *  == COMPARA VALOR Y HACE CAST DE TIPO ...ejemplo A = 123 , B = "123" A==B ES CORRECTO (TRUE), PORQUE LOS COMPARA COMO TEXTO (CAST),
   PERO A===B ES INCORRECTO (FALSO) PORQUE COMPARA TIPO DE DATO, SI HAY UN STRING Y UN NUMERO LOS COMPARA POR LO QUE SON. * */

  // VERIFICA SI EL CODIGO ES APTO PARA SER AGREGADO, PASA POR ALGUNAS CONDIFICIONES HASTA LLEGAR A LLAMAR LA FUNCION agregarCod QUE SE ENCUENTRA ARRIBA.
  const handleAddCod = () => {
    if (props.selectKeyword === "") {
      getNull();
      return;
    }
    if (props.selectKeyword.includes(props.search)) {
      noFound();
      return;
    } else {
      agregarCod();
    }
  };

  // EN CASO DE NO QUERER ESE CODIGO, SE ELIMINA CON ESTA FUNCION.
  const handleRemoveCod = (cod) => {
    const removeCod = selectResults.filter((cods) => cods.codigo !== cod);
    setSelectResults(removeCod);
  };

  const [numOrden, setNumOrden] = useState({
    num_orden: "",
  });

  const handleChanges = (e) => {
    setValidarOrden("");
    const { name, value } = e.target;
    setNumOrden((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [validarOrden, setValidarOrden] = useState("");
  const totcodigos = selectResults.length;

  const suma = selectResults.map((item) => parseInt(item.cantidad));
  const cajas = suma.reduce((prev, next) => prev + next, 0);

  const handleClean = () => {
    const limpiar = [];
    setSelectResults(limpiar);
    props.handleClose();
    setValidarOrden("");
  };

  const actionAfterUpadteCods = () => {
    props.handleClose();
    setValidarOrden("");
    handleClean();
    alert("Se han actualizado " + totcodigos + " codigos correctamente");
    window.location.reload();
  };

  const [tienda, setTienda] = useState({
    sucursal: "",
  });

  const handleTienda = (e) => {
    const { name, value } = e.target;
    setTienda((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(tienda);
  };

  // FUNCION PARA MANDAR A LA BASE DE DATOS
  // todo: hay que cambiar esto a la carpeta services/methods para que se vea mejor el codigo.
  const handleSend = async () => {
    if (!numOrden.num_orden) {
      setValidarOrden("Numero de orden vacio");
    } else if (!selectResults.length > 0) {
      alert("No se ha ingresado ningun codigo");
    } else {
      if (props.Enviar === "Entrada") {
        await Database.post("/Procedure_entrada.php", {
          selectResults,
          numOrden,
        }).then((response) => {
          console.log(response);
        });
        actionAfterUpadteCods();
      } else if (!tienda.sucursal) {
        alert("No se ingreso ninguna sucursal");
      } else if (props.Enviar === "Salida") {
        await Database.post("/Procedure_salida.php", {
          selectResults,
          numOrden,
          tienda,
        }).then((response) => {
          console.log(response);
        });
        actionAfterUpadteCods();
      }
    }
  };

  return (
    <div>
      <input
        name="num_orden"
        placeholder={props.inputOrden}
        className={validarOrden ? classes.inputorden_error : classes.inputorden}
        onChange={handleChanges}
      ></input>
      {validarOrden && <p className="message_error">{validarOrden}</p>}

      <div
        className={props.Enviar === "Salida" ? "display-block" : "display-none"}
      >
        <FormControl
          className={!validarOrden ? classes.formControl : classes.formControl2}
        >
          <InputLabel name="valores" htmlFor="grouped-native-select">
            Tienda
          </InputLabel>
          <Select
            name="sucursal"
            native
            defaultValue=""
            id="grouped-native-select"
            onChange={handleTienda}
          >
            <option aria-label="None" value={-1}>
              Seleccionar opción
            </option>
            {sucursal.map((item, i) => {
              return (
                <option value={item.sucursal} key={"valores" + i}>
                  {item.sucursal}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>

      <Selectbar
        searchword={props.search}
        show={openSelectBar}
        searchCodigo={props.searchCodigo}
        handleAdd={handleAddCod}
        selectKeyword={props.selectKeyword}
        resultado={selectResults}
        generateNewData={"a"}
        clase="select-input"
      />
      <TableContainer className={classes.rowscroll}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.headmaster}>
              <TableCell
                style={{ backgroundColor: "#eef2f3" }}
                className={classes.head}
              >
                Linea
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#eef2f3" }}
                className={classes.head}
              >
                Codigo
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#eef2f3" }}
                className={classes.head}
              >
                Producto
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#eef2f3", width: 10 }}
                className={classes.head}
              >
                Inventario
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#eef2f3" }}
                align="right"
                className={classes.head}
              >
                Cantidad
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#eef2f3", width: "1px" }}
                align="right"
                className={classes.head}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Row
              key={props.key}
              history={selectResults}
              handleRemove={handleRemoveCod}
              setDatos={setSelectResults}
            ></Row>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.lastdiv}>
        <InputLabel className={classes.totcods}>
          Codigos: {totcodigos}
        </InputLabel>

        <InputLabel className={classes.totcods}>Cajas: {cajas}</InputLabel>
        <Button
          align="rigth"
          className={classes.btncancel}
          color="secondary"
          variant="outlined"
          onClick={handleClean}
        >
          Cancelar
        </Button>
        <Button
          align="rigth"
          className={classes.btnsend}
          color="default"
          variant="outlined"
          startIcon={<box-icon name="send"></box-icon>}
          onClick={handleSend}
        >
          {props.Enviar}
        </Button>
      </div>
    </div>
  );
}
export default TableContainModal;
