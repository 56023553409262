import { useState, useEffect } from "react";
import {
  getCodigos,
  getHistorial,
  getTiendas,
  getVueltas,
  getUsers,
  getRoles,
  getSucursales,
} from "../services/getDatabase";

/** --------------- USE_CODIGOS  --------------- **/
/** --------------------   -------------------- **/
export const useCodigos = () => {
  const [isLoading, setLoading] = useState(true);
  const [datos, setData] = useState([]);
  useEffect(() => {
    getCodigos().then((data) => {
      setLoading(false);
      setData(data);
    });
  }, []);
  return {
    isLoading,
    datos,
  };
};

/** --------------- USE_HISTORIAL  --------------- **/
/** --------------------   -------------------- **/
export const useHistory = () => {
  const [history, setHistory] = useState([]);
  useEffect(() => {
    getHistorial().then((data) => {
      setHistory(data);
    });
  }, []);
  return {
    history,
  };
};

/** --------------- USE_TIENDA  --------------- **/
/** --------------------   -------------------- **/
export const useTiendas = () => {
  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
    getTiendas().then((data) => {
      setTiendas(data);
    });
  }, []);
  return {
    tiendas,
  };
};

/** --------------- USE_VUELTAS  --------------- **/
/** --------------------   -------------------- **/
export const useVueltas = () => {
  const [vueltas, setVueltas] = useState([]);
  useEffect(() => {
    getVueltas().then((data) => {
      setVueltas(data);
    });
  }, []);
  return {
    vueltas,
  };
};

/** --------------- USE_USERS  --------------- **/
/** --------------------   -------------------- **/
export const useUsers = () => {
  const [user, setUser] = useState([]);
  useEffect(() => {
    getUsers().then((users) => {
      setUser(users);
    });
  }, []);
  return {
    user,
  };
};

/** --------------- USE_ROLES --------------- **/
/** --------------------   -------------------- **/
export const useRoles = () => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    getRoles().then((roles) => {
      setRoles(roles);
    });
  }, []);
  return {
    roles,
  };
};

export const useSurtimiento = () => {
  const [sucursal, setSucursales] = useState([]);
  useEffect(() => {
    getSucursales().then((surte) => {
      setSucursales(surte);
    });
  }, []);
  return {
    sucursal,
  };
};
