import React, { useRef, useState } from "react";
import {
  makeStyles,
  Icon,
  Button,
  InputLabel,
  FormControl,
  Select,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import putCodigo from "../../../services/methods/Method_Codigo_Put";
import { Database } from "../../../api/Database";

const useStyles = makeStyles({
  title: {
    color: "red",
    marginRight: "15px",
  },
  inputlabel: {
    color: "#8E0E00",
    fontSize: "22px",
    marginBottom: "10px",
  },
  inputtext: {
    color: "black",
    textAlign: "center",
    marginBottom: "10px",
  },
  labelad: {
    color: "black",
    textAlign: "center",
    fontSize: "25px",
    marginBottom: "10px",
  },
  btn1: {
    marginRight: "0px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  btn2: {
    marginLeft: "30px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  labelad1: {
    marginTop: "15px",
    marginBottom: "-10px",
    fontSize: "20px",
    color: "#1F1C18",
  },
  headtable: {
    fontVariantCaps: "all-small-caps",
    textAlign: "left",
    width: "120px",
    borderBottom: "2px solid #c31432",
  },
  bodytable: {
    fontVariantCaps: "all-small-caps",
    textAlign: "left",
    width: "120px",
    tabSize: "small",
  },
});

export const EditModal = ({ codigo, handleClose, handleEditar }) => {
  const RefCantidad = useRef(null);

  const classes = useStyles();

  const [newCodeCant, setNewCodeCant] = useState({
    categoria: "",
    linea: "",
    codigo: "",
    producto: "",
    cantidad: "",
  });

  const onChangeCantidad = (cantidad) => {
    const cant = cantidad.target.value;
    const newCant = codigo.map((cod) => {
      return {
        categoria: cod.categoria,
        codigo: cod.codigo,
        producto: cod.producto,
        linea: cod.linea,
        cantidad: cant,
      };
    });
    setNewCodeCant(newCant);
  };

  // const enviarCambio = async () => {
  //   var form = new FormData();
  //   form.append("categoria", newCodeCant.categoria);
  //   form.append("linea", newCodeCant.linea);
  //   form.append("codigo", newCodeCant.codigo);
  //   form.append("producto", newCodeCant.producto);
  //   form.append("cantidad", newCodeCant.cantidad);

  //   await putCodigo(form);
  // };

  const enviarCambio = async () => {
    await Database.put("/Edit_Codigos.php", {
      newCodeCant,
    }).then((response) => {
      console.log(response);
      alert("La cantidad ha sido actualizada");

      window.location.reload();
    });
  };

  return (
    <div>
      <TableContainer className={classes.tblescosa}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              ></TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Linea
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Codigo
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Producto
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Cantidad
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Nueva Cantidad
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              {codigo.map((row) => (
                <TableRow key={row.producto}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.bodytable}
                  ></TableCell>
                  <TableCell className={classes.bodytable}>
                    {row.linea}
                  </TableCell>
                  <TableCell className={classes.bodytable}>
                    {row.codigo}
                  </TableCell>
                  <TableCell className={classes.bodytable}>
                    {row.producto}
                  </TableCell>
                  <TableCell className={classes.bodytable}>
                    {row.cantidad}
                  </TableCell>
                  <TableCell className={classes.bodytable}>
                    <input
                      name="cantidad"
                      ref={RefCantidad}
                      onChange={onChangeCantidad}
                    ></input>
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.btn1}
        onClick={enviarCambio}
      >
        CONFIRMAR
      </Button>
      <Button
        type="button"
        variant="contained"
        color="primary"
        className={classes.btn2}
        onClick={handleClose}
      >
        CANCELAR
      </Button>
    </div>
  );
};
