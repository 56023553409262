import React from "react";
import { Icon } from "@material-ui/core";
import "boxicons";
import "../styles/acsstyle.css";

const NavPhone = () => {
  return (
    <div className="nav-item2 dropdown ">
      <li
        className="nav-link"
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <Icon color="secondary">menu</Icon>
      </li>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <li>
          <a href="/phone">
            <i>
              <box-icon name="menu-alt-left" color="black"></box-icon>
            </i>
            <span className="links_name">Movimiento</span>
          </a>
        </li>
        <li>
          <a href="/phonesearch">
            <i>
              <box-icon name="search" color="black"></box-icon>
            </i>
            <span className="links_name">Busqueda</span>
          </a>
        </li>
        {/* <li> <a className="dropdown-item disable" href="#" > </a></li> */}
      </ul>
    </div>
  );
};

export default NavPhone;
