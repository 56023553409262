import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import Rows from "./subcontainer/tablecontainrow";
import PropTypes from "prop-types";
import Pagination from "../../componentes/Pagination";

const useTableStyle = makeStyles({
  tblescosa: {
    textAlign: "center",
    maxWidth: "98%",
    maxHeight: "720px",
    marginLeft: "1%",
  },
  headtable: {
    color: "yellow",
    fontVariantCaps: "all-small-caps",
    fontSize: "20px",
    textAlign: "left",
    borderBottom: "2px solid #c31432",
  },
  divdowntable: {
    marginTop: 15,
    color: "white",
    display: "flex",
  },
  count: {
    position: "sticky",
    left: "35px",
    marginLeft: "45%",
    right: "70px",
  },
  page: {
    position: "sticky",
    marginLeft: "2%",
    left: "20px",
  },
  tablepage: {
    left: "20px",
    color: "white",
    marginTop: -42,
    position: "sticky",
  },
  head: {
    borderBottom: "2px solid #414345",
    backgroundColor: "#3a6073",
  },
  paganation: {
    position: "sticky",
    marginLeft: "15%",
    marginTop: "1%",
  },
});

Rows.protoType = {
  rows: PropTypes.shape({
    linea: PropTypes.string.isRequired,
    codigo: PropTypes.string.isRequired,
    producto: PropTypes.string.isRequired,
    cantidad: PropTypes.string.isRequired,
  }).isRequired,
};

function ContainerTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(11);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const count = props.codigosbase.length;
  const classes = useTableStyle();
  const keyProp = props.codigosbase.map((codigos) => codigos.codigo);
  const pagecount = page + 1;

  return (
    <div>
      <TableContainer className={classes.tblescosa} key={keyProp}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Linea
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Codigo
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Producto
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Cantidad
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Rows
              rows={props.codigosbase}
              key={props.codigosbase.codigo}
              rowpage={rowsPerPage}
              page={page}
            ></Rows>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.divdowntable}>
        <div className={classes.page}>Pagina: {pagecount}</div>
        <div className={classes.count}> Codigos: {count}</div>
        <div className={classes.paganation}>
          <TablePagination
            className={classes.tablepage}
            rowsPerPageOptions={[10]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
          />
        </div>
      </div>
    </div>
  );
}

export default ContainerTable;
