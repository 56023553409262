import React, { useState, useRef } from "react";
import {
  makeStyles,
  Button,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import {
  useError_getNull,
  useError_noFound,
  useError_noRepeat,
} from "../../hooks/useError";
import { Database } from "../../api/Database";
import produce from "immer";
import Modal from "../../componentes/ventanamodal";
import useModal from "../../hooks/useModal";

import { useSurtimiento } from "../../hooks/useDatabase";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
  },
}));

function MethodsPhone(props) {
  const { sucursal } = useSurtimiento();
  const classes = useStyles();

  const [isOpenCantidad, openCantidad, closeCantidad] = useModal();

  const getNull = useError_getNull;
  const noFound = useError_noFound;
  const noRepeat = useError_noRepeat;

  const [selectResults, setSelectResults] = useState([]);
  const agregarCod = () => {
    if (
      !selectResults.find((selected) => {
        return selected.codigo === props.search[0].codigo;
      })
    ) {
      const newResults = props.search.map((selectecod) => {
        return {
          codigo: selectecod.codigo,
          producto: selectecod.producto,
          linea: selectecod.linea,
          cant: selectecod.cantidad,
          cantidad: "",
        };
      });
      setSelectResults([...selectResults, ...newResults]);
      openCantidad();

      // console.log(selectResults);
    } else {
      noRepeat();
      closeCantidad();
      getSelectTerm((inputSel.current.value = ""));
    }
  };

  const handleAddCod = () => {
    if (props.selectKeyword === "") {
      getNull();
      closeCantidad();
      return;
    }
    if (props.selectKeyword.includes(props.search)) {
      noFound();
      getSelectTerm((inputSel.current.value = ""));
      closeCantidad();
      return;
    } else {
      agregarCod();
    }
  };

  const totcodigos = selectResults.length;

  const suma = selectResults.map((item) => parseInt(item.cantidad));
  // console.log(suma);

  const cajas = suma.reduce((prev, next) => prev + next, 0);
  // console.log(cajas);

  const handleClean = () => {
    const limpiar = [];
    setSelectResults(limpiar);
    props.handleCancelar();
    // getSelectTerm((inputSel.current.value = ""));
  };

  const actionAfterUpadteCods = () => {
    handleClean();
    alert("Se han actualizado " + totcodigos + " codigos correctamente");
    window.location.reload();
  };

  const handleCancelar = () => {
    const limpiar = [];
    setSelectResults(limpiar);
    props.handleCancelar();
    getSelectTerm((inputSel.current.value = ""));
  };

  const inputSel = useRef("");

  const getSelectTerm = () => {
    props.searchCodigo(inputSel.current.value);
  };

  const validarCodigo = (e) => {
    if (e.key === "Enter") {
      if (inputSel === "") {
        closeCantidad();
      } else {
        props.searchCodigo(inputSel.current.value);
        handleAddCod();
        console.log(selectResults);
        return;
      }
    }
  };

  const validarCantidad = (e) => {
    closeCantidad();
    getSelectTerm((inputSel.current.value = ""));
  };

  const searchCode = () => {
    if (props.selectKeyword === "") {
      getNull();
      getSelectTerm((inputSel.current.value = ""));
      closeCantidad();
      return;
    }
    if (
      selectResults.find((selected) => {
        return selected.codigo === inputSel.current.value;
      })
    ) {
      selectResults.map((a) => a.codigo === props.search);
      openCantidad();
      setBtnInput(true);
    } else {
      getSelectTerm((inputSel.current.value = ""));
      noFound();
      closeCantidad();
    }
  };

  const editCodigo = (e) => {
    if (e.key === "Enter") {
      if (inputSel === "") {
        closeCantidad();
      } else {
        props.searchCodigo(inputSel.current.value);
        searchCode();
        console.log(selectResults);
        return;
      }
    }
  };

  const eliminarCodigo = (codigo) => {
    const newResult = selectResults.filter((a) => a.codigo !== codigo);
    setSelectResults(newResult);
    getSelectTerm((inputSel.current.value = ""));
    closeCantidad();
  };

  const [tienda, setTienda] = useState({
    sucursal: "",
  });

  const handleTienda = (e) => {
    const { name, value } = e.target;
    setTienda((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(tienda);
    console.log(e.target);
  };

  const handleSend = async () => {
    const numOrden = props.numOrden;
    console.log(numOrden);
    if (!selectResults.length > 0) {
      alert("No se ha ingresado ningun codigo");
    } else {
      if (props.title === "Entrada") {
        console.log(selectResults, numOrden);
        await Database.post("/Procedure_entrada.php", {
          selectResults,
          numOrden,
        }).then((response) => {
          console.log(response);
        });
        actionAfterUpadteCods();
      } else if (!tienda.sucursal) {
        alert("No se ingreso ninguna sucursal");
      } else if (props.title === "Salida") {
        await Database.post("/Procedure_salida.php", {
          selectResults,
          numOrden,
          tienda,
        }).then((response) => {
          console.log(response);
        });
        actionAfterUpadteCods();
      }
    }
  };

  const [btnInput, setBtnInput] = useState(true);

  const handleChangeBTN = () => {
    setBtnInput(!btnInput);
  };
  //
  return (
    <div>
      <div
        className={props.title === "Salida" ? "display-block" : "display-none"}
      >
        <FormControl className={classes.formControl}>
          <InputLabel name="valores" htmlFor="grouped-native-select">
            Tienda
          </InputLabel>
          <Select
            name="sucursal"
            native
            defaultValue=""
            id="grouped-native-select"
            onChange={handleTienda}
          >
            <option aria-label="None" value={-1}>
              Seleccionar opción
            </option>
            {sucursal.map((item, i) => {
              return (
                <option value={item.sucursal} key={"valores" + i}>
                  {item.sucursal}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <p className="txtTitle">
        {props.title} de {props.category}
      </p>

      <p className="txtTitle">LISTA DE CODIGOS: {totcodigos}</p>
      <p className="txtTitle">Cajas {cajas}</p>

      {btnInput ? (
        <div>
          <input
            ref={inputSel}
            name="busqueda"
            type="text"
            placeholder="Codigo"
            value={props.selectKeyword}
            onChange={getSelectTerm}
            autoFocus
            onKeyPress={(e) => validarCodigo(e)}
          ></input>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleChangeBTN}
            style={{ marginLeft: 10 }}
            // startIcon={<box-icon name="edit" type="solid"></box-icon>}
          >
            Editar
          </Button>
        </div>
      ) : (
        <div>
          <input
            ref={inputSel}
            name="busqueda"
            type="text"
            placeholder="Codigo"
            value={props.selectKeyword}
            onChange={getSelectTerm}
            autoFocus={true}
            onKeyPress={(e) => editCodigo(e)}
          ></input>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeBTN}
            style={{ marginLeft: 10 }}
            // startIcon={<box-icon name="add-to-queue"></box-icon>}
          >
            Agregar
          </Button>
        </div>
      )}
      <Modal
        show={isOpenCantidad}
        handleClose={closeCantidad}
        clase="phone-modal"
        title="Cantidad"
      >
        {selectResults.map((adds, index) =>
          inputSel.current.value === adds.codigo ? (
            <div align="center" style={{ marginLeft: 5 }}>
              <p style={{ color: "white" }}>{adds.producto}</p>
              <p style={{ color: "white" }}>{adds.codigo}</p>
              <p style={{ color: "white", fontSize: 25 }}>
                Inventario <br></br>
                {adds.cant}
              </p>
              <input
                placeholder="Cantidad"
                autoFocus
                type="number"
                style={{ width: 70, height: 25, marginLeft: 5 }}
                onChange={(e) => {
                  const cantidad = e.target.value;
                  setSelectResults((currentDatos) =>
                    produce(currentDatos, (v) => {
                      v[index].cantidad = cantidad;
                    })
                  );
                }}
                key={adds.codigo}
                name={adds.codigo}
                pattern="[0-9]{0,13}"
              ></input>
              <br></br>
              <Button
                style={{ marginTop: 10, marginLeft: 78, width: "120px" }}
                variant="contained"
                onClick={validarCantidad}
              >
                OK
              </Button>
              <Button
                variant="outlined"
                style={{ marginTop: 10, marginLeft: 18 }}
                onClick={() => eliminarCodigo(adds.codigo)}
              >
                <box-icon name="trash" color="red"></box-icon>
              </Button>
            </div>
          ) : (
            <div></div>
          )
        )}
      </Modal>
      <div style={{ marginLeft: "10%", marginTop: 90 }}>
        <Button
          variant="contained"
          style={{ marginRight: 20 }}
          onClick={handleSend}
          startIcon={<box-icon name="send"></box-icon>}
        >
          Enviar
        </Button>
        <Button variant="contained" onClick={handleCancelar}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}

export default MethodsPhone;
