import React from "react";
import {
  TableCell,
  TableRow,
  makeStyles,
  Icon,
  Button,
} from "@material-ui/core";
import produce from "immer";

const useRowStyle = makeStyles({
  codcell: {
    color: "black",
  },
});

export default function Row({ history, handleRemove, setDatos }) {
  const classes = useRowStyle();

  return (
    <React.Fragment>
      {history.map((adds, index) => (
        <TableRow key={adds.codigo}>
          <TableCell className={classes.codcell}>{adds.linea}</TableCell>
          <TableCell
            className={classes.codcell}
            component="th"
            scope="row"
            name="codigo"
          >
            {adds.codigo}
          </TableCell>
          <TableCell className={classes.codcell}>{adds.producto}</TableCell>
          <TableCell className={classes.codcell} align="center">
            {adds.cant}
          </TableCell>
          <TableCell className={classes.codcell} align="right">
            <input
              placeholder="Cantidad"
              autoFocus
              type="number"
              style={{ width: 100 }}
              onChange={(e) => {
                const cantidad = e.target.value;
                setDatos((currentDatos) =>
                  produce(currentDatos, (v) => {
                    v[index].cantidad = cantidad;
                  })
                );
              }}
              key={adds.codigo}
              name={adds.codigo}
              pattern="[0-9]{0,13}"
            ></input>
          </TableCell>

          <TableCell>
            <Button onClick={() => handleRemove(adds.codigo)}>
              <box-icon name="trash" type="solid" color="red"></box-icon>
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}
