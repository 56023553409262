import React from "react";
import { Switch, Route } from "react-router-dom";
import Inicio from "../views/Page_Login";
import Main from "../views/Page_Main";
import vueltas from "../views/Page_Gasolina";
import gastos from "../views/Page_Gastos";
import methodpage from "../views/Method/Page_Method";
import tablepage from "../views/Page_Table";
import register from "../views/Page_Register";
import history from "../views/Page_History";
import datehistory from "../views/Page_DateHistory";

import phonemoves from "../views/Cellphone/Phone_Moves";
import phonesearch from "../views/Cellphone/Phone_Search";
import Menu from "../componentes/Menu";
//import PrivateRoute from "./PrivateRoutes";
const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Inicio} />
      <Route exact path="/main" component={Main} />
      <Route exact path="/vueltas" component={vueltas} />
      <Route exact path="/gastos" component={gastos} />
      <Route exact path="/methodpage" component={methodpage} />
      <Route exact path="/tablepage" component={tablepage} />
      <Route exact path="/tablepage" component={tablepage} />
      <Route exact path="/register" component={register} />
      <Route exact path="/historial" component={history} />
      <Route exact path="/phone" component={phonemoves} />
      <Route exact path="/phonesearch" component={phonesearch} />
      <Route exact path="/menu" component={Menu} />
      <Route exact path="/datehistory" component={datehistory} />

      <Route path="*">
        <h1 style={{ color: "white", alignContent: "center" }}>
          404 Not Found
        </h1>
      </Route>
    </Switch>
  );
};

export default Routes;
