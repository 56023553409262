import React from "react";
import { TableCell, TableRow, makeStyles } from "@material-ui/core";

const useBodyStyle = makeStyles({
  bodytable: {
    color: "white",
    fontSize: "18px",
    fontVariantCaps: "all-small-caps",
    textAlign: "left",
    tabSize: "small",
  },
});

function Rows(props) {
  const classes = useBodyStyle();

  return (
    <React.Fragment>
      {(props.rowpage > 0
        ? props.rows.slice(
            props.page * props.rowpage,
            props.page * props.rowpage + props.rowpage
          )
        : props.rows
      ).map((row) => (
        <TableRow key={row.producto}>
          <TableCell component="th" scope="row" className={classes.bodytable}>
            {row.linea}
          </TableCell>
          <TableCell className={classes.bodytable}>{row.codigo}</TableCell>
          <TableCell className={classes.bodytable}>{row.producto}</TableCell>
          <TableCell className={classes.bodytable}>{row.cantidad}</TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}
export default Rows;
