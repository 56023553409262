import React, { useRef, useState } from "react";
import { makeStyles, Button, Icon } from "@material-ui/core";
import postSucursal from "../../../services/methods/Method_Sucursal";

const useStyles = makeStyles({
  inputlabel: {
    marginLeft: 6,
    minWidth: 150,
    minHeight: 40,
    marginTop: 20,
    marginBottom: 8,
    width: "99.2%",
  },
  drop: {
    marginLeft: "120%",
    width: "100%",
    marginTop: "70px",
  },
  text: {
    color: "white",
    fontSize: "30px",
    marginLeft: "43.5%",
  },
  text: {
    color: "white",
    fontSize: "20px",
    marginBottom: "-30px",
    marginLeft: "4%",
  },
  btnsend: {
    position: "center",
    marginLeft: "30%",
    marginTop: "50px",
    backgroundColor: "#bdc3c7",
    width: "200px",
    color: "black",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  h1: {
    fontSize: "60px",
    color: "white",
  },
});

export default function NewMarket() {
  const classes = useStyles();

  const RefTienda = useRef(null);

  const [sucursal, setSucursal] = useState({
    sucursal: "",
  });

  const handleChanges = (e, val) => {
    const { name, value } = e.target;
    setSucursal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const enviarNuevosDatos = async () => {
    var form = new FormData();
    form.append("sucursales", sucursal.sucursal);

    // await console.log(sucursal.sucursal);
    await postSucursal(form);
    RefTienda.current.value = "";
  };

  return (
    <div className={classes.drop}>
      <h3 className={classes.h1}>Nueva Tienda</h3>
      <label className={classes.text}>Tienda</label>
      <input
        ref={RefTienda}
        name="sucursal"
        className={classes.inputlabel}
        onChange={handleChanges}
        placeholder="Nombre de la tienda"
      ></input>

      <Button
        className={classes.btnsend}
        onClick={enviarNuevosDatos}
        startIcon={<Icon>send</Icon>}
      >
        Agregar
      </Button>
    </div>
  );
}
