import React from "react";
import admin from "../img/admin.png";

const Usuarios = () => {
  const imagen = (
    <img src={admin} width="100%" height="100%" alt="imgUsuario"></img>
  );
  const user = "Dehesas";
  return [imagen, user];
};

export default Usuarios;
