import { Database } from "../../api/Database";

export default async function postCodigos(form) {
  await Database.post("/Table_codigos.php", form).then((response) => {
    console.log(form);
    console.log(response);
    // alert("El codigo ha sido agregado correctamente.");
    // window.location.reload();
  });
}
