import "../styles/ventanamodl.css";
import { Icon, Button } from "@material-ui/core";

// PROPS :  handleClose, show, title, clase, codigos
const Modal2 = ({ handleClose, show, title, clase, children, color }) => {
  const showHideClassName = show
    ? "modal2 display-block"
    : "modal2 display-none";

  return (
    <div className={showHideClassName}>
      <section className={clase}>
        <div align="center">
          <p1>{title}</p1>
          <Button
            onClick={() => handleClose()}
            // variant="outlined"
            color={(color = "" ? "default" : color)}
            startIcon={<box-icon name="x-circle"></box-icon>}
            style={{ marginLeft: "150px " }}
          ></Button>
        </div>

        {children}
      </section>
    </div>
  );
};

export default Modal2;
