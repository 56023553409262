import "../styles/ventanamodl.css";
import { Icon, Button } from "@material-ui/core";

// PROPS :  handleClose, show, title, clase, codigos
const Modal = ({ handleClose, show, title, clase, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className={clase}>
        <div align="end">
          <Button
            onClick={() => handleClose()}
            // variant="outlined"
            color="default"
            startIcon={<box-icon name="x-circle"></box-icon>}
          ></Button>
        </div>

        <div align="center">
          <h2>{title}</h2>
        </div>

        {children}
      </section>
    </div>
  );
};

export default Modal;
