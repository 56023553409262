import React from "react";
import { TableCell, TableRow, makeStyles } from "@material-ui/core";

const useBodyStyle = makeStyles({
  bodytable: {
    color: "white",
    fontSize: "17px",
    fontVariantCaps: "all-small-caps",
    textAlign: "center",
    tabSize: "small",
  },
});

function TableHookRow({vueltas , page, rowsPerPage}) {
  const classes = useBodyStyle();

  return (
    <React.Fragment>
      {(rowsPerPage > 0
        ? vueltas.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : vueltas
      ).map((vuelta) => (
        <TableRow>
          <TableCell component="th" scope="row" className={classes.bodytable}>
            {vuelta.persona}
          </TableCell>
          <TableCell className={classes.bodytable}>
            {vuelta.vehiculo}
          </TableCell>
          <TableCell className={classes.bodytable}>
            {vuelta.tienda}
          </TableCell>
          <TableCell className={classes.bodytable}>
            {vuelta.sucursal}
          </TableCell>
          <TableCell className={classes.bodytable}>
            $ {vuelta.gasolina}
          </TableCell>
          <TableCell className={classes.bodytable}>
            {vuelta.kilometraje}
          </TableCell>
          <TableCell className={classes.bodytable}>
            {vuelta.factura}
          </TableCell>
          <TableCell className={classes.bodytable}>
            $ {vuelta.cantidad}
          </TableCell>
          <TableCell className={classes.bodytable}>
            {vuelta.fecha}
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}
export default TableHookRow;
