import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import ReactExport from "react-export-excel";
const useStyles = makeStyles({
  button: {
    backgroundColor: "#4CA1AF",
    "&:hover": {
      backgroundColor: "black",
    },
    display: "flex",
    width: "80%",
    marginBottom: "4px",
    color: "white",
    borderRadius: "90px",
  },
});

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelSheet;
const ExcelColumn = ReactExport.ExcelColumn;

function ExcelExportData({ lineas, datos }) {
  const classes = useStyles();
  return (
    <div align="center">
      {lineas.map((line) => {
        return (
          <ExcelFile
            element={
              <Button key={line} className={classes.button} type="button">
                {line}
              </Button>
            }
            filename={line}
          >
            <ExcelSheet
              data={datos.filter((row) => row.linea === line)}
              name="Inventario"
            >
              <ExcelColumn label="Linea" value="linea" />
              <ExcelColumn label="Codigo" value="codigo" />
              <ExcelColumn label="Producto" value="producto" />
              <ExcelColumn label="Cantidad" value="cantidad" />
            </ExcelSheet>
          </ExcelFile>
        );
      })}
    </div>
  );
}

export default ExcelExportData;
