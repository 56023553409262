import React, { useState } from "react";
import { useCodigos } from "../../hooks/useDatabase";
import "../../styles/phone.css";
import Searchbar from "../../componentes/Searchbar";
import useModal from "../../hooks/useModal";
import NavPhone from "../../componentes/NavPhone";

function Phone_Search() {
  const { datos } = useCodigos();
  const data = datos;
  console.log(data);

  const [isOpenSearchBar, openSearchBar, closeSearchBar] = useModal();

  // const searchKeyword = () => {
  //     if()
  // }
  const [show, setShow] = useState(false);
  const showHiddenClass = show ? "display-blocl" : "display-none";
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([data]);
  const searchHandler = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const searchList = data.filter((rows) => rows.codigo === searchTerm);
      setSearchResults(searchList);
      setShow(true);
    }
  };

  const limpiar = () => {
    openSearchBar();
    setShow(false);
  };

  return (
    <div>
      <div className="phonediv">
        <NavPhone />
        <h1 className="txtTitle">Busqueda de codigo</h1>
      </div>
      <div className="searchdiv">
        <Searchbar
          show={openSearchBar}
          term={searchTerm}
          searchKeyword={searchHandler}
          handleClose={limpiar}
          autoFocus={true}
        />
      </div>

      <div align="center" className="resultsdiv">
        {searchResults.map((a) => {
          return (
            <div className={showHiddenClass} key={a.codigo}>
              <p className="cantidad">{a.cantidad}</p>
              <p className="codigo">{a.codigo}</p>
              <p className="linea">{a.linea}</p>
              <p className="producto">{a.producto}</p>
              {/* <p className="resultp">Cantidad</p> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Phone_Search;
