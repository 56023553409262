import React, { useState } from "react";
import { Icon, Button } from "@material-ui/core";
import { Database } from "../api/Database";
// import { Link, Redirect } from "react-router-dom";
// import Main from "../views/Page_Main";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/inicio.css";
import logo from "../img/logo1.png";
// import login from "../services/methods/Method_Login";

function Inicio() {
  const [getUserData, setGetUserData] = useState({
    username: "",
    password: "",
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setGetUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log(getUserData);
  };

  const userLoginData = {
    user: "",
    name: "",
    rol: "",
  };

  const [isLogged, setIsLogged] = useState(false);

  const handleLogin = async () => {
    // await login(getUserData);
    await Database.post("/User_login.php", getUserData).then((response) => {
      if (response.data.success) {
        userLoginData.user = response.data.credential.username;
        userLoginData.name = response.data.credential.nombre;
        userLoginData.rol = response.data.credential.idRolUsuario;
        setIsLogged(response.data.success);
        // ReactDOM.render(<Redirect to="/main" />);
      } else {
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <main className="inicio">
      <header className="headerinicio d-flex">
        <div className="container text-center my-auto">
          <span className="hmb"> Productos y Servicios Dehesas </span> <br></br>
          <span className="mb-5">
            <em>Sistema control de inventario</em>
          </span>
        </div>
      </header>

      <div className="container p-4" onKeyPress={handleKeyPress}>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card text-center">
              <div className="card-header">
                {/* HEADER DE PAGINA INICIO  */}
                <h3> Iniciar Sesión</h3>
                <img src={logo} alt="" className="img center my-auto" />
              </div>
              <div className="card-body">
                {/* USUARIO - FORMATO DE VALIDACION */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <Icon className="iconformat" style={{ fontSize: 30 }}>
                        person
                      </Icon>
                    </span>
                    <input
                      name="username"
                      placeholder="Usuario"
                      className="us form-control"
                      aria-label="usuario"
                      aria-describedby="basic-addon1"
                      autoFocus
                      // ref={refUsername}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                {/* CONTRASEÑA - FORMATO DE VALDACION */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon2">
                      <Icon className="iconformat" style={{ fontSize: 30 }}>
                        lock
                      </Icon>
                    </span>
                    <input
                      type="password"
                      name="password"
                      className="pw form-control"
                      placeholder="Contraseña"
                      aria-label="contraseña"
                      aria-describedby="basic-addon2"
                      // ref={refPassword}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <Button
                  className="btninicio "
                  style={{
                    backgroundColor: "#E2E2E2",
                    fontSize: 20,
                    borderRadius: 10,
                    width: 250,
                  }}
                  startIcon={<Icon>doneall</Icon>}
                  onClick={handleLogin}
                >
                  Inicar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Inicio;
