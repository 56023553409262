import React, { useRef, useState } from "react";
import { makeStyles, Icon, Button, InputLabel } from "@material-ui/core";

import { useCodigos } from "../../../hooks/useDatabase";
import useModal from "../../../hooks/useModal";
import Modal from "../../../componentes/ventanamodal";
import { Warning } from "../../../componentes/Warning";
import DeleteCods from "../../../services/methods/Method_Codigo_Delete";
const useStyles = makeStyles({
  select: {
    display: "flex",
    marginLeft: "580px",
    marginTop: "50px",
    fontSize: "20px",
  },
  h3text: {
    width: "100%",
    marginLeft: "580px",
    marginTop: "10%",
    fontSize: "40px",
    color: "white",
  },
  h3textOpen: {
    width: "100%",
    marginLeft: "40%",
    marginTop: "2%",
    fontSize: "40px",
    color: "white",
  },
  selectOpen: {
    display: "flex",
    marginLeft: "40%",
    marginTop: "60px",
    fontSize: "20px",
  },
  divform: {
    marginLeft: "0%",
    width: "1500px",
  },
  btnclear: {
    marginLeft: "60%",
    backgroundColor: "#C9D6FF",
    marginTop: "-60px",
  },
  formdelete: {
    marginLeft: "48%",
    marginTop: "30px",
    width: 900,
  },
  formtxt: {
    color: "black",
    fontSize: "30px",
    textDecoration: "underline",
    marginBottom: "7%",
    marginLeft: "-2%",
  },
  deletetxt: {
    color: "black",
    fontSize: "30px",
    marginBottom: "7%",
  },
});

function DeleteCode() {
  const [isOpenModal, openModal, closeModal] = useModal();

  const { datos } = useCodigos();

  const [deleteResults, setDelectResults] = useState([]);
  const [deleteKeyword, setDeleteKeyword] = useState("");
  const [deleteW, setDeleteW] = useState([]);
  const searchCodigo = (selected) => {
    setDeleteKeyword(selected);
    if (selected !== "") {
      const deleteList = datos.filter((rows) => {
        return Object.values(rows)
          .join(" ")
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(selected.toLowerCase());
      });
      setDeleteW(deleteList);
    }
  };

  const deleteSelected = () => {
    deleteW.map((select) => {
      const deleteCod = {
        linea: select.linea,
        codigo: select.codigo,
        producto: select.producto,
      };
      return setDelectResults([deleteCod]), setDeleteCodigo(deleteCod);
    });
  };

  const classes = useStyles();
  const inputDel = useRef("");

  const [isOpenDiv, openDiv, closeDiv] = useModal();

  const showHiddenClass = isOpenDiv ? classes.divform : "display-none";

  const getSelectTerm = () => {
    searchCodigo(inputDel.current.value);
  };
  const getNull = () => {
    alert("Agrega un codigo para buscar");
    return alert;
  };

  const noFound = () => {
    alert("Codigo no encontrado");
    return alert;
  };

  const noRepeat = () => {
    alert("Codigo ya existe ");
    return alert;
  };

  const abrirDeleteWindow = () => {
    if (deleteKeyword === "") {
      getNull();
      return;
    }
    if (deleteKeyword.includes(deleteW)) {
      noFound();
      getSelectTerm((inputDel.current.value = ""));
      return;
    }
    if (deleteW === deleteResults.codigo) {
      noRepeat();
      return;
    } else {
      getSelectTerm((inputDel.current.value = ""));
      deleteSelected();
      openDiv();
    }
  };
  //BTN LIMPIAR
  const cleanInputDelete = (deletearray) => {
    const cleanDelete = deleteResults.filter(
      (item) => item.codigo !== deletearray
    );
    setDelectResults([cleanDelete]);
    closeDiv();
    return;
  };

  const [deleteCodigo, setDeleteCodigo] = useState({
    codigo: "",
    linea: "",
    producto: "",
  });

  const handleClickDelete = async () => {
    var eliminar = new FormData();
    eliminar.append("codigo", deleteCodigo.codigo);
    await DeleteCods({ params: { codigo: deleteCodigo.codigo } });
    // await Database.delete("/index.php" , {params: {codigo: deleteCodigo.codigo}})
    cleanInputDelete();
  };

  const handleBtnCancelar = () => {
    closeModal();
    cleanInputDelete();
  };

  return (
    <div>
      <h3 className={isOpenDiv ? classes.h3textOpen : classes.h3text}>
        Eliminar Codigos
      </h3>

      <section>
        <div className={isOpenDiv ? classes.selectOpen : classes.select}>
          <input
            ref={inputDel}
            name="codigo"
            type="text"
            placeholder="Codigo"
            value={deleteKeyword}
            onChange={getSelectTerm}
            autoFocus
          ></input>
          <Button
            onClick={() => abrirDeleteWindow()}
            variant="contained"
            color="default"
            startIcon={<Icon>search</Icon>}
          ></Button>
        </div>

        {deleteResults.map((keydelete) => (
          <div className={showHiddenClass}>
            <Button
              className={classes.btnclear}
              startIcon={<Icon>clear</Icon>}
              onClick={() => cleanInputDelete(keydelete.codigo)}
            >
              Limpiar
            </Button>
            <div className={"container p-4"}>
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="card text-center">
                    <div className="card-header">
                      {/* HEADER DE PAGINA INICIO  */}
                      <h3> Elemento a eliminar</h3>
                    </div>
                    <div className="card-body">
                      {/* USUARIO - FORMATO DE VALIDACION */}
                      <div className="input-group mb-3">
                        <div className="input-prepend mx-auto">
                          <span
                            className="input-group-text "
                            id="basic-addon1"
                          ></span>
                          <InputLabel className={classes.formtxt}>
                            LINEA:
                          </InputLabel>
                          <InputLabel
                            className={classes.deletetxt}
                            name="linea"
                            value={keydelete.linea}
                          >
                            {keydelete.linea}
                          </InputLabel>
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                          ></span>
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                          ></span>
                          <InputLabel className={classes.formtxt}>
                            CODIGO:
                          </InputLabel>
                          <InputLabel
                            className={classes.deletetxt}
                            name="codigo"
                            value={keydelete.codigo}
                          >
                            {keydelete.codigo}
                          </InputLabel>
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                          ></span>
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                          ></span>
                          <InputLabel className={classes.formtxt}>
                            PRODUCTO:
                          </InputLabel>
                          <InputLabel
                            className={classes.deletetxt}
                            name="producto"
                            value={keydelete.producto}
                          >
                            {keydelete.producto}
                          </InputLabel>
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                          ></span>
                        </div>
                      </div>
                      <Modal
                        show={isOpenModal}
                        handleClose={closeModal}
                        clase="modal-main"
                      >
                        <Warning
                          deleteResults={deleteResults}
                          handleClose={handleBtnCancelar}
                          handleClickDelete={handleClickDelete}
                        />
                      </Modal>
                      <Button
                        style={{
                          backgroundColor: "#c31432",
                          fontSize: 20,
                          borderRadius: 10,
                          width: 250,
                          color: "white",
                          transform: "transition scale(1.07)",
                        }}
                        startIcon={<Icon>doneall</Icon>}
                        onClick={openModal}
                      >
                        ELiminar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default DeleteCode;
