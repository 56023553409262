import React from "react"; //, {useState}
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./Routes/Routes";
//import Inicio from "./views/Page_Login";
import AuthProvider from "./auth/AuthProvider";
//import { Redirect } from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <Routes>{/* <Redirect to="/main" /> */}</Routes>
    </AuthProvider>
  );
}

export default App;
