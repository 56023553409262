import React, { useState } from "react";
import "../../styles/acsstyle.css";
import "./Component/Categorys.css";

import Navegacion from "../../componentes/Navbar";
import Menu from "../../componentes/Menu";

import { makeStyles, Button } from "@material-ui/core";
import MethodoPost from "../../containers/methods/MethodPost";
// import MethodPostNew from "../containers/methods/MethodPostNew";
import MethodoDelete from "../../containers/methods/MethodDelete";
import MenuCategory from "./Component/MenuCategory";

// SUBVIEWS
import NewCode from "./Containers/NewCode";
import NewLine from "./Containers/NewLine";
import EditCant from "./Containers/EditCant";
import EditCode from "./Containers/EditCode";
import DeleteCode from "./Containers/DeleteCode";
import NewMarket from "./Containers/NewMarket";
import DeleteMarket from "./Containers/DeleteMarket";

const useStyle = makeStyles({
  header: {
    display: "flex",
  },
  title: {
    fontSize: "50px",
    letterSpacing: "10px",
    color: "white",
    fontFamily: "",
    fontVariantCaps: "all-small-caps",
    marginLeft: "43.5%",
  },
  btn: {
    width: "50%",
    backgroundColor: "#076585",
    color: "white",
  },
  btnactive: {
    width: "50%",
    backgroundColor: "#2c3e50",
    color: "white",
  },
});

// CONSTANTE PARA DIFERENCIAR MIS VENTANAS
const titleVentana = [{ value: "Agregar" }, { value: "Eliminar" }];

function ActionCods() {
  const classes = useStyle();

  // CONSTANTE PARA DECLARAR LA VENTANA ACTIVA, EN ESTE CASO, EL PREDETERMINADO ES "AGREGAR"
  const [btnActivo, setBtnActivo] = useState("Agregar");

  // CONSTANTE PARA TOMAR EL CAMBIO DE VENTANA
  const handleChangeAction = (dato) => {
    dato === "Agregar" ? setBtnActivo("Agregar") : setBtnActivo("Eliminar");
  };

  const [childrenView, setChildrenView] = useState();

  const handleLink = (link) => {
    console.log(link);
    if (link === "Agregar Codigo") {
      setChildrenView("agregarcode");
    }
    if (link === "Editar Cantidad") {
      setChildrenView("editarcantidad");
    }
    if (link === "Editar Codigo") {
      setChildrenView("editarcode");
    }
    if (link === "Eliminar Codigo") {
      setChildrenView("deletecode");
    }
    if (link === "Nueva Linea") {
      setChildrenView("newline");
    }
    if (link === "Nueva Tienda") {
      setChildrenView("newmarket");
    }
    if (link === "Eliminar Tienda") {
      setChildrenView("deletemarket");
    }
    console.log(childrenView);
  };

  return (
    <div className="bgpage">
      {/* ----------------- BARRA DE NAVEGACION ----------------- */}
      {/* <Navegacion /> */}
      <Menu />

      <div className="method" style={{ marginLeft: 80 }}>
        <div className="menu-category" align="left">
          <h3
            align="center"
            style={{
              color: "white",
              marginBottom: 0,
              fontSize: 30,
            }}
          >
            <box-icon name="category" color="white"></box-icon> Categorias
          </h3>
          <ul>
            <MenuCategory
              name="Codigos"
              items={[
                "Agregar Codigo",
                "Editar Codigo",
                "Editar Cantidad",
                "Eliminar Codigo",
              ]} // "Editar Codigo",
              click={handleLink}
            />
            <MenuCategory
              name="Lineas"
              items={["Nueva Linea"]}
              click={handleLink}
            />
            <MenuCategory
              name="Tiendas"
              items={["Nueva Tienda", "Eliminar Tienda"]}
              click={handleLink}
            />
            {/* <MenuCategory
              name="Historial"
              items={["Modificar", "Eliminar"]}
              click={handleLink}
            /> */}
          </ul>
        </div>
        <div>
          {childrenView === "agregarcode" ? <NewCode /> : ""}
          {childrenView === "editarcantidad" ? <EditCant /> : ""}
          {childrenView === "deletecode" ? <DeleteCode /> : ""}
          {childrenView === "newline" ? <NewLine /> : ""}
          {childrenView === "newmarket" ? <NewMarket /> : ""}
          {childrenView === "deletemarket" ? <DeleteMarket /> : ""}
          {childrenView === "editarcode" ? <EditCode /> : ""}
        </div>
      </div>
    </div>
  );
}

export default ActionCods;
