import React from 'react'
import {Button, makeStyles, Icon, InputLabel} from "@material-ui/core";

const useStyles = makeStyles({
    title:{
        color: "red",
        marginRight: "15px"
    },
    inputlabel:{
        color:"#8E0E00",
        fontSize:"22px",
        marginBottom: "10px"
    },
    inputtext:{
        color: "black",
        textAlign: "center",
        marginBottom: "10px"
    },
    labelad:{
        color: "black",
        textAlign: "center",
        fontSize: "25px",
        marginBottom: "10px"
    },
    btn1:{
        marginRight: "0px",
        marginTop: "20px",
        marginBottom: "10px"
    },
    btn2:{
        marginLeft: "30px",
        marginTop: "20px",
        marginBottom: "10px"
    },
    labelad1:{
        marginTop:"15px",
        marginBottom:"-10px",
        fontSize: "20px",
        color: "#1F1C18"
    }
})

export const Warning = ({deleteResults , handleClose , handleClickDelete}) => {
    const classes = useStyles();
    return (
        <div>
            <h2 className={classes.title}> <Icon style={{ fontSize: 40, marginBottom: -8 }}>warning</Icon> &nbsp; ADVERTENCIA</h2>
            {deleteResults.map((item)=>(
                <div >
                    <InputLabel className={classes.labelad}> Estas apunto de eliminar el siguiente dato:</InputLabel>
                    <InputLabel className={classes.inputtext}>CODIGO: <InputLabel className={classes.inputlabel}>{item.codigo}</InputLabel></InputLabel>
                    <InputLabel className={classes.inputtext}>LINEA: <InputLabel className={classes.inputlabel}>{item.linea}</InputLabel></InputLabel>
                    <InputLabel className={classes.inputtext}>PRODUCTO: <InputLabel className={classes.inputlabel}>{item.producto}</InputLabel></InputLabel>
                </div>
            ))}
            <InputLabel className={classes.labelad1}>¿Estas seguro? </InputLabel>
            <Button type="button" variant="contained" color="secondary" className={classes.btn1} onClick={handleClickDelete}>CONFIRMAR</Button>
            <Button type="button" variant="contained" color="primary" className={classes.btn2} onClick={handleClose}>CANCELAR</Button>
        </div>
    )
}


