import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  makeStyles,
  TablePagination,
  Button,
} from "@material-ui/core";
// import Rows from "../containers/tablerow";
// import PropTypes from "prop-types";
import HistoryRows from "./tablehisearchrow";

const useTableStyle = makeStyles({
  tblescosa: {
    textAlign: "center",
    maxWidth: "98%",
    maxHeight: "720px",
    marginLeft: "1%",
  },
  headtable: {
    color: "yellow",
    fontVariantCaps: "all-small-caps",
    fontSize: "20px",
    textAlign: "center",
  },
  divdowntable: {
    marginTop: "50px",
    color: "white",
    display: "absolute",
  },
  count: {
    position: "sticky",
    left: "35px",
    marginLeft: "50%",
    right: "70px",
    fontSize: "12px",
  },
  page: {
    position: "sticky",
    left: "20x",
    color: "black",
    marginLeft: "30px",
    fontSize: "13px",
    marginTop: "-20px",
  },
  tablepage: {
    left: "20px",
    color: "black",
    marginTop: "-35px",
    position: "sticky",
  },
});

function TableHistory(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(8);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const count = props.history.length;
  const classes = useTableStyle();

  // const pagecount = page + 1;

  return (
    <div>
      <div className={props.btnEntradaSalida}>
        <Button
          variant="outlined"
          onClick={() => props.searchFilter("todo")}
          style={{ fontSize: 11, marginLeft: 10 }}
        >
          Todo
        </Button>
        {props.movesHistorial.map((a) => {
          return (
            <Button
              variant="outlined"
              onClick={() => props.searchFilter(a)}
              style={{ fontSize: 11, marginLeft: 14 }}
            >
              {a}
            </Button>
          );
        })}
      </div>
      <TableContainer className={classes.tblescosa}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Sucursal
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Linea
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Codigo
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Producto
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Movimiento
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                {props.factura}
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#3a6073" }}
                className={classes.headtable}
              >
                Fecha
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <HistoryRows
              history={props.history}
              key={props.history.codigo}
              rowpages={rowsPerPage}
              pages={page}
            ></HistoryRows>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.divdowntable}>
        <div className={classes.page}>Codigos: {count}</div>

        <TablePagination
          className={classes.tablepage}
          rowsPerPageOptions={[10]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />
      </div>
    </div>
  );
}

export default TableHistory;
