import React, { useState } from "react";
import { Icon, Button, makeStyles } from "@material-ui/core";
import "../styles/acsstyle.css";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Navegacion from "../componentes/Navbar";
import Modal from "../componentes/ventanamodal";
import useModal from "../hooks/useModal";
import Filterdata from "../componentes/Filterdata";
import Searchbar from "../componentes/Searchbar";
import TableContainModal from "../containers/modaltable/modaltable";
import ContainerTable from "../containers/container/tablecontainer";
import { useCodigos } from "../hooks/useDatabase";
import { useLocation } from "react-router";
import ExcelExportData from "../services/exportexcel";

const useStyles = makeStyles({
  titletxt: {
    fontSize: "50px",
    letterSpacing: "10px",
    color: "#E4E5E6",
    fontFamily: "Rubik Mono One, sans-serif",
    fontVariantCaps: "all-small-caps",
    textShadow: "2px 2px 5px #000000",
  },
});

function TablePage() {
  /* FUNCION PARA TRAER EL TITULO DESDE LA MAINPAGE */
  const location = useLocation();
  const { title } = location.state;

  /* FUNCION PARA TRAER LOS COGIOS DESDE MI HOOK Y LUEGO FILTRAR CATEGORIAS */
  const { datos } = useCodigos();
  const data = datos.filter((cat) => cat.categoria === title);

  const classes = useStyles();
  /*  FUNCION useMolda PARA PODER ABRIR/CERRAR */
  const [isOpenEntradaModal, openEntradaModal, closeEntradaModal] = useModal();
  const [isOpenSalidaModal, openSalidaModal, closeSalidaModal] = useModal();
  const [isOpenSearchBar, openSearchBar, closeSearchBar] = useModal();
  const [isOpenFilter, openFilter, closeFilter] = useModal();
  const [isOpenImportar, openImportar, closeImportar] = useModal();

  /* FUNCION PARA ABRIR Y CERRAR MI BARRA DE BUSQUEDA Y DARLE UN CSS EN CADA ESTADO */
  const openBar = isOpenSearchBar
    ? "searchbtn display-none"
    : "searchbtn display-block";
  const classBtn = isOpenSearchBar ? "btnsO" : "btnsF";

  /* AQUI VEMOS TODAS LAS LINEAS DE NUESTRA BASE DE DATOS */
  const allLineas = ["Todo", ...new Set(data.map((item) => item.linea))];
  const importLine = [...new Set(data.map((item) => item.linea))];
  /* BUSQUEDA DE FAMILIA, CODIGO Y/O PRODUCTOS */
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(data);
  const searchHandler = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const nuevaLista = data.filter((rows) => {
        return Object.values(rows)
          .join(" ")
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(nuevaLista);
    } else {
      setSearchResults(data);
    }
  };

  /* FILTRO DE DATOS A TRAVES DE LA "LINEA" */
  const filterdata = (checkbox) => {
    if (checkbox === "Todo") {
      setSearchResults(data);
      closeFilter();
      return;
    }
    const filteredData = data.filter((rows) => rows.linea === checkbox);
    setSearchResults(filteredData);
    closeFilter();
  };

  /* ESTO TIENE FUNCION PARA BUSCAR CODIGOS EN "ENTRADA" Y "SALIDA" MODAL
   DESPUES ES GUARDADO EN UN ARRAY */
  const [selectKeyword, setSelectKeyword] = useState("");
  const [search, setSearch] = useState([]);
  const searchCodigo = (selected) => {
    setSelectKeyword(selected);
    if (selected !== "") {
      const searchList = data.filter((rows) => rows.codigo === selected);
      setSearch(searchList);
    }
  };

  return (
    <div className="bgpage">
      {/* ----------------- BARRA DE NAVEGACION ----------------- */}
      <Navegacion />
      {/* ----------------- HEADER ----------------- */}
      <main>
        <header>
          <div className="container text-center mx-auto">
            <span className={classes.titletxt}>
              <em>{title}</em>
            </span>
          </div>
        </header>
        {/* ----------------- BOTONES FILTRAR Y BUSCAR ----------------- */}
        <div className="botones">
          <div className="btns">
            {/* ----------------- BOTON DE FILTRADO ----------------- */}
            <Modal
              show={isOpenFilter}
              handleClose={closeFilter}
              title="Filtrar"
              clase="filter-modal"
            >
              <Filterdata
                filterdata={filterdata}
                checkbox={allLineas}
                handleClose={closeFilter}
                key={data.codigo}
              ></Filterdata>
            </Modal>
            <Button
              variant="contained"
              color="primary"
              onClick={openFilter}
              // startIcon={<Icon>reorder</Icon>}
              startIcon={<box-icon name="filter" color="white"></box-icon>}
            >
              Filtrar
            </Button>
            {/* ----------------- BOTON DE BUSQUEDA ----------------- */}
            <div className={openBar}>
              <Button
                onClick={openSearchBar}
                variant="contained"
                color="default"
                style={{ height: "36px" }}
                // startIcon={<Icon>search</Icon>}
                startIcon={<box-icon name="search"></box-icon>}
              ></Button>
            </div>
            <Searchbar
              show={isOpenSearchBar}
              handleClose={closeSearchBar}
              term={searchTerm}
              searchKeyword={searchHandler}
              clase="select-input"
            />
          </div>

          {/*--------------BOTONES DE EXPORTAR, SALIDA Y ENTRADA--------------*/}
          <div className={(classBtn, "btnmainpage")}>
            {/* ----------------- BOTON DE MODAL EXPORTAR ----------------- */}
            <Modal
              show={isOpenImportar}
              handleClose={closeImportar}
              title="Exportar"
              clase="import-modal"
            >
              <ExcelExportData
                lineas={importLine}
                handleClose={closeFilter}
                datos={data}
                key={data.codigo}
              />
            </Modal>
            <Button
              type="button"
              onClick={openImportar}
              style={{ color: "black", background: "yellow" }}
              variant="contained"
              size="small"
              className="btnExcelPage"
              // endIcon={<ImportExportIcon />}
              endIcon={<box-icon name="export" color="black"></box-icon>}
            >
              Exportar
            </Button>
            {/* ----------------- BOTON DE MODAL ENTRADA ----------------- */}
            <Modal
              show={isOpenEntradaModal}
              handleClose={closeEntradaModal}
              title="Entrada"
              clase="modal-moves"
            >
              <TableContainModal
                inputOrden="Num. de guia"
                codigos={data}
                key={data.codigo}
                Enviar="Entrada"
                search={search}
                selectKeyword={selectKeyword}
                searchCodigo={searchCodigo}
                handleClose={closeEntradaModal}
              ></TableContainModal>
            </Modal>
            <Button
              type="button"
              onClick={openEntradaModal}
              variant="contained"
              color="primary"
              className="btnMainPageAdd"
              // startIcon={<Icon>add</Icon>}
              startIcon={<box-icon name="plus-circle" color="white"></box-icon>}
            >
              Entrada
            </Button>
            {/* ----------------- BOTON DE MODAL SALIDA ----------------- */}
            <Modal
              show={isOpenSalidaModal}
              handleClose={closeSalidaModal}
              title="Salida"
              clase="modal-moves"
            >
              <TableContainModal
                inputOrden="Factura"
                codigos={searchResults}
                selectdata={searchTerm}
                Enviar="Salida"
                search={search}
                selectKeyword={selectKeyword}
                searchCodigo={searchCodigo}
                handleClose={closeSalidaModal}
              ></TableContainModal>
            </Modal>
            <Button
              type="button"
              onClick={openSalidaModal}
              variant="contained"
              color="secondary"
              className="btnMainPage"
              // startIcon={<Icon>remove</Icon>}
              startIcon={
                <box-icon name="minus-circle" color="white"></box-icon>
              }
            >
              Salida
            </Button>
          </div>
        </div>
        {/* ----------------- TABLA ----------------- */}
        <ContainerTable
          codigosbase={searchResults.length === 0 ? data : searchResults}
          term={searchTerm}
          searchKeyword={searchHandler}
        />
      </main>
    </div>
  );
}

export default TablePage;
