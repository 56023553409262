import React, { useRef, useState } from "react";
import {
  makeStyles,
  InputLabel,
  FormControl,
  Select,
  Button,
} from "@material-ui/core";

import { useSurtimiento } from "../../../hooks/useDatabase";
import deleteMarket from "../../../services/methods/Method_Market";

const useStyles = makeStyles({
  inputlabel: {
    marginLeft: 6,
    minWidth: 150,
    minHeight: 40,
    marginTop: 20,
    marginBottom: 8,
    width: "99.2%",
  },
  drop: {
    marginLeft: "40%",
    width: "100%",
    marginTop: "70px",
  },
  text: {
    color: "white",
    fontSize: "30px",
    marginLeft: "43.5%",
  },
  text: {
    color: "white",
    fontSize: "20px",
    marginBottom: "-30px",
    marginLeft: "4%",
  },
  btnsend: {
    position: "center",
    marginLeft: "38%",
    marginTop: "50px",
    backgroundColor: "#bdc3c7",
    width: "200px",
    color: "black",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  h1: {
    fontSize: "60px",
    color: "white",
  },
  formControl: {
    marginTop: 60,
    margin: 1,
    width: 900,
    minHeight: 40,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 10,
  },
});

export default function DeleteMarket() {
  const classes = useStyles();
  const { sucursal } = useSurtimiento();

  const [sucursales, setSucursal] = useState({
    sucursal: "",
  });

  const handleChanges = (e, val) => {
    const { name, value } = e.target;
    setSucursal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const eliminarDatos = async () => {
    var form = new FormData();
    form.append("sucursales", sucursales.sucursal);
    // await console.log(sucursales.sucursal);
    await deleteMarket({ params: { sucursales: sucursales.sucursal } });
    console.log(sucursales.sucursal);
  };

  return (
    <div className={classes.drop}>
      <h3 className={classes.h1}>Eliminar Tienda</h3>

      <FormControl className={classes.formControl}>
        <InputLabel name="valores" htmlFor="grouped-native-select">
          Tienda
        </InputLabel>
        <Select
          name="sucursal"
          native
          defaultValue=""
          id="grouped-native-select"
          onChange={handleChanges}
        >
          <option aria-label="None" value={-1}>
            Seleccionar opción
          </option>
          {sucursal.map((item, i) => {
            return (
              <option value={item.sucursal} key={"valores" + i}>
                {item.sucursal}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <Button
        style={{
          backgroundColor: "#c31432",
          fontSize: 20,
          borderRadius: 10,
          width: 250,
          color: "white",
          marginLeft: 300,
          marginTop: 90,
          transform: "transition scale(1.07)",
        }}
        onClick={() => eliminarDatos()}
      >
        ELiminar
      </Button>
    </div>
  );
}
