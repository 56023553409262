import { Database } from "../api/Database";

/** --------------- GET_CODIGOS  --------------- **/
/** --------------------   -------------------- **/
export const getCodigos = async () => {
  const resp = await Database.get("/Table_codigos.php");
  const data = resp.data;
  return transformDataIntoData(data);
};
const transformDataIntoData = (data) => {
  const dataArr = data.map((results) => {
    const categoria = results.categoria;
    const linea = results.linea;
    const codigo = results.codigo;
    const producto = results.producto;
    const cantidad = results.cantidad;
    return {
      categoria,
      linea,
      codigo,
      producto,
      cantidad,
    };
  });
  return dataArr;
};
/** --------------- GET_HISTORIAL  --------------- **/
/** --------------------   -------------------- **/
export const getHistorial = async () => {
  const resp = await Database.get("/Table_history.php");
  const history = resp.data;
  return transformDataIntoHistory(history);
};
const transformDataIntoHistory = (history) => {
  const historyArr = history.map((results) => {
    const movimiento = results.movimiento;
    const sucursal = results.sucursal;
    const categoria = results.categoria;
    const linea = results.linea;
    const codigo = results.codigo;
    const producto = results.producto;
    const cantidad = results.cantidad;
    const num_orden = results.num_orden;
    const fecha = results.fecha;
    return {
      movimiento,
      sucursal,
      categoria,
      linea,
      codigo,
      producto,
      cantidad,
      num_orden,
      fecha,
    };
  });
  return historyArr;
};
/** --------------- GET_TIENDA  --------------- **/
/** --------------------   -------------------- **/
export const getTiendas = async () => {
  const resp = await Database.get("/Table_tiendas.php");
  const tiendas = resp.data;
  return transformDataIntoTienda(tiendas);
};
const transformDataIntoTienda = (tienda) => {
  const tiendaArr = tienda.map((results) => {
    const tiendas = results.tienda;
    const sucursales = results.sucursales;
    return {
      tiendas,
      sucursales,
    };
  });
  return tiendaArr;
};
/** --------------- GET_VUELTAS  --------------- **/
/** --------------------   -------------------- **/
export const getVueltas = async () => {
  const resp = await Database.get("/Table_vueltas.php");
  const vueltas = resp.data;
  return transformDataIntoVueltas(vueltas);
};
const transformDataIntoVueltas = (vuelta) => {
  const VueltaArr = vuelta.map((results) => {
    const persona = results.nombre;
    const vehiculo = results.vehiculo;
    const tienda = results.tiendas;
    const sucursal = results.sucursal;
    const gasolina = results.gasolina;
    const kilometraje = results.kilometraje;
    const factura = results.factura;
    const cantidad = results.cantidad;
    const fecha = results.fecha;
    return {
      persona,
      vehiculo,
      tienda,
      sucursal,
      gasolina,
      kilometraje,
      factura,
      cantidad,
      fecha,
    };
  });
  return VueltaArr;
};
/** --------------- GET_USERS  --------------- **/
/** --------------------   -------------------- **/
export const getUsers = async () => {
  const resp = await Database.get("/usuarios.php");
  const users = resp.data;
  return transformDataIntoUsers(users);
};
const transformDataIntoUsers = (user) => {
  const userArr = user.map((results) => {
    const id = results.id;
    const nombre = results.nombre;
    const username = results.username;
    return {
      id,
      nombre,
      username,
    };
  });
  return userArr;
};
/** --------------- GET_LOGIN  --------------- **/
/** --------------------   -------------------- **/

// export const loginUser = async({datauser}) =>{
//     console.log(datauser);
//    await Database.post("/login.php", datauser)
//     // await bcrypt.compare(password , users.password);
// }

/** --------------- GET_ROLES  --------------- **/
/** --------------------   -------------------- **/
export const getRoles = async () => {
  const resp = await Database.get("/Table_roles.php");
  const roles = resp.data;
  return transformDataIntoRoles(roles);
};
const transformDataIntoRoles = (rol) => {
  const rolArr = rol.map((roll) => {
    const id = roll.idRolUsuario;
    const rol = roll.rolUsuario;
    const descripcion = roll.descripcionRol;
    return {
      id,
      rol,
      descripcion,
    };
  });
  return rolArr;
};

export const getSucursales = async () => {
  const resp = await Database.get("/Table_Surtimiento.php");
  const surtimiento = resp.data;
  return transformDataIntoSucursales(surtimiento);
};
const transformDataIntoSucursales = (surt) => {
  const surtArr = surt.map((surte) => {
    const id = surte.idSucursal;
    const sucursal = surte.sucursales;
    return {
      id,
      sucursal,
    };
  });
  return surtArr;
};
