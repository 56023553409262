import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Box,
  Collapse,
  Typography,
  Button,
} from "@material-ui/core/";
import HistoryBody from "./historybody";

const useStyle = makeStyles({
  bgpage: {
    backgroundColor: "#DBDBDB",
    marginTop: "2px",
  },
  capletter: {
    fontVariantCaps: "all-small-caps",
    fontSize: "23px",
    textDecoration: "underline",
  },
  headletter: {
    fontVariantCaps: "all-small-caps",
    borderBottom: "2px solid #414345",
  },
});

function HistoryTable(props) {
  const classes = useStyle();

  //const movimientos = [...new Set(props.history.map((a) => a.movimiento))];

  return (
    <TableContainer
      component={Paper}
      className={classes.bgpage}
      key={props.llave}
    >
      <Table aria-label="collapsible table">
        <TableBody key={props.lineas}>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={props.open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography
                    varian="h6"
                    gutterBottom
                    component="div"
                    className={classes.capletter}
                    key={props.lineas}
                  >
                    HISTORIAL - " {props.lineas} "
                    {props.movesHistorial.map((a) => {
                      return (
                        <Button onClick={() => props.historyFilter(a)}>
                          {a}
                        </Button>
                      );
                    })}
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead className={classes.headletter}>
                      <TableRow key={props.lineas}>
                        <TableCell>Sucursal</TableCell>
                        <TableCell>Codigo</TableCell>
                        <TableCell>Producto</TableCell>
                        <TableCell align="center">Movimeinto</TableCell>
                        <TableCell align="center">{props.factura}</TableCell>
                        <TableCell align="center">Fecha</TableCell>
                      </TableRow>
                    </TableHead>
                    {props.history.map((elementos) => (
                      <HistoryBody
                        llave={props.llave}
                        elemento={elementos}
                        detailsShown={props.detailsShown}
                      />
                    ))}
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default HistoryTable;
